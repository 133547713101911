import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { addressInfoMiddleware } from '~/portal/middleware'
import {
	certificationMiddleWare
} from '~/portal/middleware/project-creation/certification'
import {
	ProjectRoutesNamesEnum
} from '~/router/modules/project-creation/projectCreationTypes'

export const getProjectRoutes = (app?: App): RouteRecordRaw => {
	return {
		path: '/project-creation',
		name: ProjectRoutesNamesEnum.projectCreation,
		component: () => import('~/portal/pages/project-creation/index.vue'),
		beforeEnter: createAuthGuard(app as any),
		children: [
			{
				path: '',
				name: ProjectRoutesNamesEnum.applicant,
				component: () => import(
					'~/portal/pages/project-creation/applicant.vue'
				),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'address',
				name: ProjectRoutesNamesEnum.address,
				beforeEnter: addressInfoMiddleware,
				component: () => import('~/portal/pages/project-creation/address.vue')
			},
			{
				path: 'certification',
				name: ProjectRoutesNamesEnum.certification,
				beforeEnter: [
					certificationMiddleWare,
					createAuthGuard(app as any)
				],
				component: () => import(
					'~/portal/pages/project-creation/certification.vue'
				)
			}
		]
	}
}
