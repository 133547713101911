<script lang="ts" setup>
import { computed } from 'vue'

import type { ICPBreadcrumbsPropTypes } from '~/portal/components/base'
import { CPLink, CPSvgIcon } from '~/portal/components/base'

const props = withDefaults(
	defineProps<ICPBreadcrumbsPropTypes>(),
	{
		dividerIcon: 'chevron-right',
		enableBackButton: false
	}
)

const emit = defineEmits<{
  (e: 'click', value: any): void
}>()

const computedLinks = computed(() => {
	if (props.enableBackButton) {
		return [props.data.slice(-2)?.[0] || props.data[0]].filter(Boolean)
	}

	return props.data
})

const onClick = (value: any): void => {
	emit('click', value)
}
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
  <nav
    v-if="computedLinks.length"
    :class="[
      'cp-breadcrumbs',
      $attrs.class
    ]"
    aria-label="breadcrumb"
  >
    <ul class="cp-breadcrumbs-list">
      <li
        v-for="(item, index) in computedLinks"
        :key="item?.label + index"
        class="cp-breadcrumbs-list__item"
        :aria-current="index === (computedLinks.length - 1)
          ? 'page'
          : undefined"
      >
        <CPSvgIcon
          v-if="enableBackButton"
          name="arrow-back"
          class="cp-breadcrumbs-list__item-back-button"
          @click="onClick(item)"
        />

        <CPLink
          v-if="item.to && item?.label"
          :to="item.to"
          class="cp-breadcrumbs-list__item-link"
          @click="onClick(item)"
        >
          <template v-if="!enableBackButton">
            {{ item.label }}
          </template>
        </CPLink>

        <span
          v-if="!item.to && item?.label"
          @click="onClick(item)"
        >
          {{ item.label }}
        </span>

        <CPSvgIcon
          v-if="index < (computedLinks.length - 1)"
          class="cp-breadcrumbs-list__item-divider"
          :name="dividerIcon"
        />
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
  .cp-breadcrumbs {
    @apply
      text-sm
      font-normal
    ;

    &-list {
      @apply
        flex
        flex-wrap
      ;

      &__item {
        @apply
          inline-flex
          items-center
          text-cp-neutral-500
          last:text-cp-neutral-300
        ;

        &-link {
          @apply
            color-inherit
            no-underline
            transition-all
          ;
        }

        &-divider {
          @apply
            inline-block
            px-2
          ;
        }

        &-back-button {
          @apply
            cursor-pointer
            text-cp-neutral-500
          ;
        }
      }
    }
  }
</style>
