import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { useSnackbar } from '~/portal/composables'
import { useAuthStore } from '~/quality-management/stores/authStore/useAuthStore'
import {
	useEmployeeStore
} from '~/quality-management/stores/employeeStore/useEmployeeStore'

export const authGuard = async (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore()
	try {
		if (!authStore.token) {
			await authStore.getAccessToken()
		}
		next()
	} catch (error) {
		console.error('Failed to fetch token:', error)
		next('/login')
	}
}

export const employeeGuard = async (to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext) => {
	const employeeStore = useEmployeeStore()
	const userId = Number(to.params.employeeId)

	try {
		const employee = await employeeStore.fetchEmployee(userId)

		if (!employee) {
			return next('/quality-management/not-found')
		} else {
			next()
		}
	} catch (error) {
		const { showSnackbar } = useSnackbar()

		showSnackbar('Etwas ist schiefgelaufen', 'cp-base-red')

		next('/quality-management')
	}
}
