import type { components } from 'schema.ts'

import { ECP_CERTIFICATION_TYPE } from '~/portal/components'
import type {
	ICPAddressParkFormPropTypes,
	ICPFormCertificationInfoPropTypes,
	ICPFormInvoicePropTypes
} from '~/portal/components/form'
import {
	CERTIFICATION,
	VOLTAGE_LVL
} from '~/portal/components/form'

export const mapFormCertificationProductTypeFromApi = (
	data: components['schemas']['GetChecklist'][]
): ICPFormCertificationInfoPropTypes['formData']['productType'] => {
	if (!data) {
		return []
	}
	return data.map((item: components['schemas']['GetChecklist']) => {
		if (item.name === ECP_CERTIFICATION_TYPE.PlantCertificate) {
			return CERTIFICATION.PlantCertificate
		}
		if (item.name === ECP_CERTIFICATION_TYPE.ConformityCheck) {
			return CERTIFICATION.ConformityCheck
		}
		return undefined
	}).filter(certification => certification !== undefined) as CERTIFICATION[]
}

export const mapFormCertificationProductTypeToApi = (
	data: ICPFormCertificationInfoPropTypes['formData']['productType']
): components['schemas']['Certification'] => {
	const certifications: components['schemas']['Certification'] = {}

	if (data.includes(CERTIFICATION.PlantCertificate)) {
		certifications.plant_certificate = true
	}

	if (data.includes(CERTIFICATION.ConformityCheck)) {
		certifications.conformity_check = true
	}
	return certifications
}

export const mapFormScopeCertificationFromApi = async (
	data: any
): Promise<ICPFormCertificationInfoPropTypes['formData']> => {
	return {
		personal: data?.scope?.own_use ? '1' : '0',
		consumption: data?.scope?.own_use
			? (data?.scope.consumption_value || '')
			: '',
		contactPerson: data?.network_operator?.contact_person || '',
		companyName: data?.network_operator?.company_name || '',
		phoneNumber: data?.network_operator?.phone_number || '',
		email: data?.network_operator?.email || '',
		city: data?.network_operator?.city || '',
		street: data?.network_operator?.street || '',
		file: data.scope?.investments?.map((item: any) => {
			return {
				name: item.name,
				file_key: item.file_path
			}
		}) || [],
		voltage: data?.scope?.voltage_lvl || VOLTAGE_LVL.Medium,
		productType: mapFormCertificationProductTypeFromApi(
			data.scope?.services
		),
		comment: data?.scope?.comment || '',
		gender: data?.network_operator?.gender || null
	}
}

export const mapPowerPlantAddressFromApi = (
	data: components['schemas']['PowerPlantAddress']
): ICPAddressParkFormPropTypes['formData'] => {
	return {
		name: data.name || '',
		street: data.street || '',
		hallway: data.hallway || '',
		city: data.city || '',
		index: data.index || ''
	}
}

export const mapPowerPlantAddressToApi = (
	data: ICPAddressParkFormPropTypes['formData']
): components['schemas']['PowerPlantAddress'] => {
	return {
		name: data.name || '',
		street: data.street || '',
		hallway: data.hallway || '',
		city: data.city || '',
		index: data.index || ''
	}
}

export const mapFormInvoiceToApi = (
	data: ICPFormInvoicePropTypes['modelValue']
): components['schemas']['GenerateInvoice'] => {
	const renderType = (item: any) => {
		if (item === 'Vorauszahlung') {
			return 'PREPAYMENT'
		}
		if (item === 'Hauptzahlung') {
			return 'MAIN_PAYMENT'
		}

		return 'ADDITIONAL_PAYMENT'
	}

	return {
		// @ts-expect-error ___
		number: data.invoiceNumber,
		custom_external_id: data.customerProjectNumber,
		type: renderType(data.invoiceType),
		additional_service: data.additionalService,
		delivery_date: data.deliveryDate,
		additional_payment_price: data.thirdProductPrice
	}
}
