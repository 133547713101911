import type { components } from 'schema.ts'

import type { IFetchApiProps } from '~/portal/api'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api'
import { STATE_STORE_KEYS } from '~/portal/types'

export interface IFetchGetTemplatesListPayload {
	token: IFetchApiProps['token']
	currentPage: number
	pageSize?: boolean
	params?: {
		ordering?: string
	}
}

export const fetchApiGetTemplatesList = async (
	payload: IFetchGetTemplatesListPayload
): Promise<
	components['schemas']['PaginatedGetTemplateList'] | undefined
> => {
	if (!payload.token && !payload.currentPage) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/template/list?page=${payload.currentPage}`,
		pendingKey: STATE_STORE_KEYS.CreationTemplate,
		token: payload.token,
		params: payload.params
	})

	if (response && response.data) {
		return response.data
	}
}

export const fetchApiGetTemplate = async (
	payload: {
		id: number
		token: IFetchApiProps['token']
	}
) => {
	if (!payload.token && !payload.id) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/template/${payload.id}`,
		pendingKey: STATE_STORE_KEYS.GetTemplate,
		token: payload.token
	})

	if (response && response.data) {
		return response.data
	}
}

export const fetchApiDeleteTemplate = async (
	payload: {
		id: number
		token: IFetchApiProps['token']
	}
) => {
	if (!payload.token && !payload.id) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Delete,
		url: `/project/template/${payload.id}/delete`,
		pendingKey: STATE_STORE_KEYS.DeleteTemplate,
		token: payload.token
	})

	if (response && response.data) {
		return response.data
	}
}
