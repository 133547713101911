import { useAuth0 } from '@auth0/auth0-vue'

export async function getAccessToken () {
	const { getAccessTokenSilently } = useAuth0()

	let token = null

	try {
		token = await getAccessTokenSilently()
	} catch (error) {
		console.error('Failed to fetch access token', error)
		throw error
	}

	return token
}
