import type { components } from 'schema.ts'

import type { IFetchApiProps } from '~/portal/api'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api'
import { STATE_STORE_KEYS } from '~/portal/types'

export const fetchApiGetClientsList = async (
	payload: {
		token: IFetchApiProps['token']
		currentPage: number
		search?: string
	}
): Promise<
	components['schemas']['PaginatedListClientsList'] | undefined
> => {
	if (!payload.token) return

	const url = payload.search
		? `/user/client/list?search=${payload.search}&return_all=true`
		: `/user/client/list?page=${payload.currentPage}`
	const response = await fetchApi({
		url,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.GetClientsList,
		token: payload.token
	})

	if (response && response.data) {
		return response.data
	}
}
