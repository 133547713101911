import { defineStore } from 'pinia'

import {
	fetchApiGetClientProjects,
	fetchClientData,
	updateCompanyClient
} from '~/portal/api'

import type { IClientStoreTypes } from './'

export const useClientStore = defineStore('clientStore', {
	state: (): IClientStoreTypes => ({
		client: {},
		projects: [],
		pageCount: undefined,
		currentPage: 1,
		isPagination: false
	}),
	actions: {
		resetProjectsList () {
			this.currentPage = 1
			this.projects = []
			this.pageCount = undefined
		},
		async fetchClientData (token: string, clientId: number) {
			this.client = {}
			const clientData = await fetchClientData(token, clientId)

			if (clientData) {
				this.client = clientData
			}
		},

		async updateTrustLevel (
			token: string,
			clientId: number,
			payload: 'HIGH' | 'LOW' | 'MEDIUM'
		) {
			const response = await updateCompanyClient(
				token,
				clientId,
				{ trust_lvl: payload }
			)

			if (response && this.client.company) {
				this.client.company.trust_lvl = payload
			}
		},

		async updateComment (
			token: string,
			clientId: number,
			payload: string
		) {
			const response = await updateCompanyClient(
				token,
				clientId,
				{ comment: payload }
			)

			if (response && this.client.company) {
				this.client.company.comment = payload
			}
		},

		async fetchClientProjects (
			token: string, clientId: number, params?: any
		) {
			if (clientId === this.client.id && this.projects?.length) return

			const response = await fetchApiGetClientProjects({
				token,
				clientId,
				currentPage: this.currentPage,
				params
			})

			if (response) {
				this.pageCount = response.count
				if (response.next) {
					this.currentPage = Number(response.next)
				} else if (response.previous) {
					this.currentPage = Number(response.previous) + 1
				} else {
					this.currentPage = 1
				}
				if (Array.isArray(this.projects) && response.results?.length) {
					this.projects?.push(...response.results)
				}
				this.isPagination = !!response.next
			}
		}
	}
})
