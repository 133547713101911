<script setup lang="ts">
import type { ICPFileUploadItemPropType } from '~/portal/components/base'
import { CPSvgIcon, formatFileSize } from '~/portal/components/base'

const props = withDefaults(
	defineProps<ICPFileUploadItemPropType>(),
	{
		showIcon: true
	}
)

const emit = defineEmits<{
  (e: 'delete'): void
  (e: 'download'): void
}>()

const onDeleteFile = (): void => emit('delete')

const onDownLoad = (): void => {
	if (!props.downloadLink) {
		return
	}

	window.location.href = props.downloadLink
	emit('download')
}
</script>

<template>
  <div
    :class="[
      'cp-file-upload-item',
      {
        'cp-file-upload-item--inverse': inverse
      }
    ]"
  >
    <CPSvgIcon
      v-if="showIcon"
      name="file"
      class="cp-file-upload-item__icon"
      :size="51"
    />
    <div
      :class="{'cp-file-upload-item__info': showIcon}"
    >
      <span class="cp-file-upload-item__name">
        {{ name }}
      </span>
      <div>
        <span
          v-if="size"
          class="cp-file-upload-item__size"
        >
          {{ formatFileSize(size) }}
        </span>
        <span
          v-if="isEditable"
          class="cp-file-upload-item__delete"
          @click="onDeleteFile"
        >
          Delete
        </span>
        <a
          v-if="downloadLink"
          :href="downloadLink"
          :download="name"
          class="cp-file-upload-item__download"
          @click="onDownLoad"
        >
          Herunterladen
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .cp-file-upload-item {
    @apply
      flex
      items-center
    ;

    letter-spacing: 0.25px;

    &--inverse {
      .cp-file-upload-item__icon {
        @apply
        text-cp-neutral-100
        ;
      }

      .cp-file-upload-item__name {
        @apply
          text-cp-neutral-100
        ;
      }

      .cp-file-upload-item__size {
        @apply
        text-cp-neutral-200
        ;
      }

      .cp-file-upload-item__download {
        @apply
        text-cp-neutral-100
        ;
      }
    }

    &__icon {
      @apply
        text-cp-accent-200
        flex-shrink-0
      ;
    }

    &__info {
      @apply
        ml-4
        md:ml-6
      ;
    }

    &__name {
      @apply
        inline-block
        font-medium
        text-[16px]
        text-cp-neutral-700
        -mb-0.5
        break-all
      ;
    }

    &__size {
      @apply
        font-normal
        text-sm
        text-cp-neutral-500
      ;

      &::after {
        content: '•';

        @apply
          ml-3
          mr-2
          text-cp-neutral-300

        ;
      }
    }

    &__delete {
      @apply
        text-cp-base-red
        text-sm
        font-medium
        cursor-pointer
      ;
    }

    &__download {
      @apply
        text-cp-neutral-400
        text-sm
        font-medium
        cursor-pointer
        decoration-none
      ;
    }
  }
</style>
