import type { components } from 'schema.ts'

import type { IFetchApiProps } from '~/portal/api'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api'
import type { USER_ROLES_IDS } from '~/portal/layouts'
import { STATE_STORE_KEYS } from '~/portal/types'

export interface IFetchApiSetSubPhasePayload {
  token: IFetchApiProps['token']
  projectId: number
  phaseId: number
  subPhaseId: number
  progress?: number
}

export interface IFetchApiAddTimeframe {
  token: IFetchApiProps['token']
  projectId: number
  payload: {
    type: string
    weeks: number
  }
}

export const fetchApiSetSubPhase = async (payload: IFetchApiSetSubPhasePayload) => {
	if (!payload.token) return
	const url = payload.progress !== 0
		? `/project/${payload.projectId}/phase/${payload.phaseId}/set-phase-progress`
		: `/project/${payload.projectId}/phase/${payload.phaseId}/set-sub-phase`

	return await fetchApi({
		token: payload.token,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.UpdateSubPhase,
		url,
		payload: {
			sub_phase_id: payload.subPhaseId,
			progress: payload.progress
		}
	})
}

export interface IFetchApiFinalizePhasePayload {
  token: IFetchApiProps['token']
  projectId: number
  phaseId: number
}

export const fetchApiFinalizePhase = async (payload: IFetchApiFinalizePhasePayload) => {
	return await fetchApi({
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.UpdateSubPhase,
		url: `/project/${payload.projectId}/phase/${payload.phaseId}/finish`
	})
}

export const fetchApiGetUserList = async (payload: {
  token: IFetchApiProps['token']
  userRoleId: USER_ROLES_IDS
}): Promise<components['schemas']['GetUser'][]> => {
	const response = await fetchApi({
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.UserList,
		url: `/user/list?role=${payload.userRoleId}`,
		forceFetch: true,
		params: {
			return_all: true
		}
	})

	return response?.data
}

export const fetchApiAddTimeframe = (
	{ token, projectId, payload }:
  IFetchApiAddTimeframe
) => {
	return fetchApi({
		token,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.AddTimeframe,
		url: `/project/${projectId}/add-timeframe`,
		payload
	})
}
