import { defineStore } from 'pinia'

import type { IFetchDeleteReport, IFetchReports } from '~/portal/api'
import { fetchApiDeleteReport, fetchApiGetReports } from '~/portal/api'

import type { IReportsStoreTypes } from './reportsStoreTypes.ts'

export const useReportsStore = defineStore('reports', {
	state: (): IReportsStoreTypes => ({
		reports: [],
		currentPage: 1,
		resultCount: 0,
		isPagination: false
	}),
	actions: {
		resetReportsPagination () {
			this.reports = []
			this.currentPage = 1
			this.resultCount = 0
			this.isPagination = false
		},

		async fetchReportList (payload: IFetchReports) {
			const response = await fetchApiGetReports({
				...payload,
				params: {
					page: this.currentPage
				}
			})

			if (response) {
				this.resultCount = response.count
				this.currentPage = response.next ??
          (response.previous + 1) ??
          1
				this.reports.push(...response.results)
				this.isPagination = !!response.next
			}
		},

		async deleteReport (payload: IFetchDeleteReport) {
			const response = await fetchApiDeleteReport(payload)

			if (response) {
				this.reports = this.reports.filter(
					(report) => report.id !== payload.reportId
				)
			}
		}
	}
})
