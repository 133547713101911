import { defineStore } from 'pinia'

import { getAccessToken } from '~/quality-management/composables/getAccessToken'
import type {
	IQMAuthStore
} from '~/quality-management/stores/authStore/authStoreTypes'
import {
	useLoaderStore
} from '~/quality-management/stores/loaderStore/useLoaderStore'

export const useAuthStore = defineStore('qmAuthStore', {
	state: (): IQMAuthStore => ({
		token: null
	}),
	actions: {
		async getAccessToken () {
			const loaderStore = useLoaderStore()
			loaderStore.toggleIsLoading()
			const token = await getAccessToken()
			if (token) this.token = token
			loaderStore.toggleIsLoading()
		}
	}
})
