import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from '~/portal/components/App.vue'
import { maskDirective } from '~/portal/directives'
import { createRouter } from '~/router'

import createAuth from '~/portal/plugins/auth0'
import { initSentry } from '~/portal/plugins/sentry'
import vuetify from '~/portal/plugins/vuetify'

import 'virtual:uno.css'
// remove mdi for production build
import '@mdi/font/css/materialdesignicons.min.css'
import '~/portal/assets/fonts/font.css'
import '~/portal/assets/styles/global.scss'

import 'virtual:svg-icons-register'
import '~/portal/api'

const router = createRouter()
const app = createApp(App)
	.directive('maska', maskDirective)
	.use(createPinia())
	.use(vuetify)
	.use(router)
	.use(createAuth() as any)

initSentry({ app, router })
app.mount('#app')
