<script setup lang="ts">
import { computed } from 'vue'
import { VCheckbox, VSwitch } from 'vuetify/components'

import type { ICPCheckboxPropTypes } from '~/portal/components/base'

const props = withDefaults(
	defineProps<ICPCheckboxPropTypes>(), {
		label: '',
		type: 'checkbox',
		errorMessage: '',
		error: false,
		disabled: false
	}
)

const emit = defineEmits<{
  (e: 'blur'): void
  (e: 'change'): void
}>()

const model = defineModel()

const hideDetails = computed(() => {
	return !(props.error && props.errorMessage)
})

const component = computed(() => {
	if (props.type === 'switch') {
		return VSwitch
	}

	return VCheckbox
})

const onChange = (): void => {
	emit('change')
}

const onBlur = (): void => {
	emit('blur')
}
</script>

<script lang="ts">
export default {
	inheritAttrs: false
}
</script>

<template>
  <component
    :is="component"
    v-model="model"
    :class="[
      'cp-checkbox',
      $attrs.class
    ]"
    :label="label"
    :value="value"
    :error="error"
    :disabled="disabled"
    :hide-details="hideDetails"
    :error-messages="errorMessage"
    @change="onChange"
    @blur="onBlur"
  >
    <template v-if="!label" #label>
      <slot name="label" />
    </template>
  </component>
</template>

<style lang="scss">
.cp-checkbox {
  .v-label {
    --v-medium-emphasis-opacity: 1;
    @apply
      fw-500
      font-size-4
      color-cp-neutral-500
      ml-2
    ;
    letter-spacing: 0.02em; // 2%
  }

  &.v-input--disabled .v-input__details {
    --v-disabled-opacity: 1;

    @apply
    color-cp-neutral-300
    ;
  }

  .v-selection-control__input {
    & > {
      .v-icon {
        --v-medium-emphasis-opacity: 1;
      }
    }

  }

  &.v-input:not(.v-input--error) .v-selection-control--dirty {
    &:not(.v-input--disabled) {
      .v-switch__track {
        @apply
        opacity-35
        bg-cp-accent-200
        ;
      }

      .v-switch__thumb {
        @apply
        bg-cp-accent-200
        ;
      }
    }
  }

  &.v-input--error {
    &:not(.v-input--disabled) {
      .v-input__details {
        .v-messages {
          @apply
            color-cp-base-red
          ;
        }
      }
    }
  }

  .v-selection-control--error {
    &:not(.v-selection-control--disabled) {
      .v-label {
        @apply
          color-cp-base-red
        ;
      }

      .v-selection-control__input {
        & > {
          .v-icon,
          .v-label {
            @apply
              color-cp-base-red
            ;
          }
        }
      }

      .v-switch__thumb {
        @apply
        bg-cp-base-red
        ;
      }

      .v-switch__track {
        @apply
        bg-cp-base-red
        ;
      }
    }
  }

  &.v-input--disabled {
    .mdi-checkbox-marked,
    .mdi-checkbox-blank-outline {
      @apply
      color-cp-neutral-200
      ;
    }
  }

  .mdi-checkbox-marked {
    @apply
    color-cp-accent-200
    ;
  }

  .mdi-checkbox-blank-outline {
    @apply
      color-cp-neutral-300
    ;
  }

  .v-switch__track {
    @apply
    bg-cp-neutral-100
    ;
  }
}
</style>
