<script setup lang="ts">
import { computed } from 'vue'

import type { ICPTypographyPropTypes } from '~/portal/components/layout'

const props = withDefaults(
	defineProps<ICPTypographyPropTypes>(), {
		label: '',
		tag: '',
		variant: 'body1',
		bold: false,
		uppercase: false
	}
)

const emit = defineEmits<{
  (e: 'click'): void
}>()

const getTagByVariant = (value: ICPTypographyPropTypes['variant']): string => {
	switch (value) {
	case 'h1':
		return 'h1'
	case 'h2':
		return 'h2'
	case 'h3':
		return 'h3'
	case 'h4':
		return 'h4'
	case 'body1':
	case 'body2':
	case 'body3':
	case 'caption1':
	case 'caption2':
		return 'p'
	case 'button1':
	case 'button2':
		return 'span'
	default:
		return 'p'
	}
}

const tagComputed = computed(() => {
	return props.tag || getTagByVariant(props.variant)
})

const classes = computed(() => {
	const cl = 'cp-typography'

	return {
		[cl]: true,
		[`${cl}--bold`]: props.bold,
		[`${cl}--uppercase`]: props.uppercase,
		[`${cl}--h1`]: props.variant === 'h1',
		[`${cl}--h2`]: props.variant === 'h2',
		[`${cl}--h3`]: props.variant === 'h3',
		[`${cl}--h4`]: props.variant === 'h4',
		[`${cl}--body1`]: props.variant === 'body1',
		[`${cl}--body2`]: props.variant === 'body2',
		[`${cl}--body3`]: props.variant === 'body3',
		[`${cl}--caption1`]: props.variant === 'caption1',
		[`${cl}--caption2`]: props.variant === 'caption2',
		[`${cl}--button1`]: props.variant === 'button1',
		[`${cl}--button2`]: props.variant === 'button2'
	}
})

const onClick = (): void => {
	emit('click')
}
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
  <Component
    :is="tagComputed"
    :class="[
      classes,
      $attrs.class
    ]"
    :title="$attrs.title"
    @click="onClick"
  >
    <slot>
      {{ label }}
    </slot>
  </Component>
</template>

<style scoped lang="scss">
  .cp-typography {
    @apply
      fw-400
    ;
    letter-spacing: 0.02em; // 2%

    &--bold {
      @apply
        fw-700
      ;
    }

    &--uppercase {
      @apply
        uppercase
      ;
    }

    &--h1,
    &--h2 {
      @apply
        fw-500
      ;
      letter-spacing: 0.01em; // 1%
    }

    &--h1 {
      @apply
        font-size-7
        leading-11
        md:(
          font-size-8
          leading-12
        )
      ;
    }

    &--h2 {
      @apply
        font-size-6
      ;
    }

    &--h3 {
      @apply
        fw-500
        font-size-5
      ;
    }

    &--h4 {
      @apply
        fw-500
        font-size-4
      ;
    }

    &--body1 {
      @apply
      font-size-4
      ;
    }

    &--body2 {
      @apply
      font-size-[0.875rem]
      ;
    }

    &--body3 {
      @apply
      font-size-3
      ;
    }

    &--button1 {
      @apply
      fw-500
      font-size-4
      ;
    }

    &--button2 {
      @apply
      fw-500
      font-size-[0.875rem]
      ;
    }

    &--caption1 {
      @apply
      fw-500
      font-size-[0.875rem]
      ;
    }

    &--caption2 {
      @apply
      fw-500
      font-size-3
      ;
    }
  }
</style>
