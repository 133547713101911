import type { components } from 'schema.ts'

import { STATE_STORE_KEYS } from '~/portal/types'

import { FETCH_API_METHODS, fetchApi } from './axios.ts'

export interface IFetchApiGetNotifications {
  token: string
  forceFetch?: boolean
  params?: {
    page?: number
    type?: string
    is_seen?: boolean
  }
}

export interface IFetchApiReadNotification {
  token: string
  id: number
}

export const fetchApiGetNotifications = async (
	{
		token,
		forceFetch,
		params
	}: IFetchApiGetNotifications
):Promise<components['schemas']['PaginatedGetNotificationList']> => {
	const response = await fetchApi({
		token,
		method: FETCH_API_METHODS.Get,
		url: '/project/notification/list',
		pendingKey: STATE_STORE_KEYS.NotificationList,
		forceFetch,
		params
	})

	return response?.data
}

export const fetchApiReadNotification = ({
	token,
	id
}: IFetchApiReadNotification) => {
	return fetchApi({
		token,
		method: FETCH_API_METHODS.Get,
		url: `/project/notification/${id}/read`,
		pendingKey: STATE_STORE_KEYS.NotificationRead
	})
}
