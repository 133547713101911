import type { components } from 'schema.ts'

import type { IFetchApiProps } from '~/portal/api/axios.ts'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api/axios.ts'
import { STATE_STORE_KEYS } from '~/portal/types'

export interface IFetchApiProtectorList {
  token: IFetchApiProps['token']
  projectId?: number
  params?: {
    page?: number
    return_all?: boolean
  }
}

export interface IFetchApiFindProterctor {
  token: IFetchApiProps['token']
  projectId: number
  payload: components['schemas']['FindProjectProtector']
}

export interface IFetchApiDeleteProtector {
  token: IFetchApiProps['token']
  projectId: number
  protectorId: number
}

export interface IFetchApiProtectorTypeList {
  token: IFetchApiProps['token']
  params?: {
    page?: number
    return_all?: boolean
  }
}

export const fetchApiProtectorList = async ({
	token,
	projectId,
	params
}: IFetchApiProtectorList) => {
	const response = await fetchApi({
		token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ProtectorList,
		url: `/project/${projectId}/protector/list`,
		params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiFindProtector = async ({
	token,
	projectId,
	payload
}: IFetchApiFindProterctor) => {
	return fetchApi({
		token,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ProtectorFind,
		url: `/project/${projectId}/protector/find`,
		payload
	})
}

export const fetchApiDeleteProtector = async ({
	token,
	projectId,
	protectorId
}: IFetchApiDeleteProtector) => {
	return fetchApi({
		token,
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.ProtectorDelete,
		url: `/project/${projectId}/protector/${protectorId}/delete`
	})
}

export const fetchApiProtectorTypeList = async ({
	token,
	params
}: IFetchApiProtectorTypeList) => {
	const response = await fetchApi({
		token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ProtectorTypeList,
		url: '/product/protector/type/list',
		params: {
			params,
			return_all: true
		}
	})

	if (response?.data) {
		return response.data
	}
}

export interface IFetchAPIProtectorCompanyList {
	token: IFetchApiProps['token']
	params?: {
		search?: string
		type_id?: string | number
		return_all?: boolean
	}
}

export const fetchAPIProtectorCompanyList = async (
	payload: IFetchAPIProtectorCompanyList
) => {
	const response = await fetchApi({
		url: '/product/protector/list',
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ProtectorCompanyList,
		params: payload.params
	})

	if (response?.data) {
		return response.data
	}
}
