import type { components } from 'schema.ts'

import type { IFetchApiProps } from '~/portal/api'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api'
import { STATE_STORE_KEYS } from '~/portal/types'

export const fetchClientData = async (
	token: IFetchApiProps['token'],
	clientId: number
): Promise<
  components['schemas']['GetCompanyClientAdmin'] | undefined
> => {
	if (!token || !clientId) return
	const url = `/user/company/${clientId}`
	const response = await fetchApi({
		url,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.Client,
		token
	})

	if (response?.data) {
		return response.data
	}
}

export const updateCompanyClient = async (
	token: IFetchApiProps['token'],
	clientId: number,
	payload: components['schemas']['PatchedUpdateCompany']
) => {
	if (!token || !clientId || !payload) return

	const url = `/user/company/${clientId}/update`
	const response = await fetchApi({
		url,
		method: FETCH_API_METHODS.Patch,
		pendingKey: STATE_STORE_KEYS.Client,
		token,
		payload
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetClientProjects = async (
	{ token, clientId, currentPage, params }:
  {
    token: string
    clientId: number
    currentPage: number
    params?: any
  }
): Promise<components['schemas']['PaginatedGetProjectListList'] | undefined> => {
	if (!token || !clientId) return

	const url = `/user/company/${clientId}/project/list?page=${currentPage}`
	const response = await fetchApi({
		url,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ClientProjects,
		token,
		params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetClientById = async (
	payload: {
		token: IFetchApiProps['token']
		id: number
	}
): Promise<
  components['schemas']['GetCompanyClientAdmin'] | undefined
	> => {
	if (!payload.token || !payload.id) return

	const url = `/user/${payload.id}`
	const response = await fetchApi({
		url,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.Client,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchPatchUpdateUserById = async (
	payload: {
		token: IFetchApiProps['token']
		id: number | undefined
		user: any
	}
) => {
	if (!payload.token && !payload.id) {
		return
	}

	const response = await fetchApi({
		method: FETCH_API_METHODS.Patch,
		url: `/user/${payload.id}/update`,
		pendingKey: STATE_STORE_KEYS.UpdateUser,
		token: payload.token,
		payload: {
			...payload.user
		}
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchDeleteUserById = async (
	payload: {
		token: IFetchApiProps['token']
		id: number | undefined
	}
) => {
	if (!payload.token && !payload.id) return

	await fetchApi({
		method: FETCH_API_METHODS.Delete,
		url: `/user/${payload.id}/delete`,
		pendingKey: STATE_STORE_KEYS.DeleteUser,
		token: payload.token
	})
}

export const fetchGetUserList = async (
	payload: {
		token: IFetchApiProps['token']
		search?: string
	}
) => {
	if (!payload.token) return

	const url = payload.search
		? `/user/list?search=${payload.search}&return_all=true`
		: '/user/list'

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url,
		pendingKey: STATE_STORE_KEYS.UserList,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}
