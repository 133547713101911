import type { components } from 'schema.ts'

import type { IFetchApiProps } from '~/portal/api'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api'
import { STATE_STORE_KEYS } from '~/portal/types'

export interface IFetchApiUploadOffer {
  token: IFetchApiProps['token']
  projectId: number
  type: string
  payload: components['schemas']['UploadOffer']
}

export const fetchApiGenerateOffer = (payload: {
  token: IFetchApiProps['token']
  projectId: number
  data: {
    valid_date: string
    applicable_guidelines: string[]
    facility_certificate_price?: number
    declaration_of_conformity_price?: number
    vat: number
    voltage_lvl: string
  }
}) => {
	if (!payload.token && !payload.data) return

	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `project/${payload.projectId}/offer/generate`,
		pendingKey: STATE_STORE_KEYS.GenerateOffer,
		token: payload.token,
		payload: payload.data
	})
}

export const fetchApiGetOffers = async (payload: {
  token: IFetchApiProps['token']
  projectId: number
  params: {
    page: number
  }
}) => {
	if (!payload.token) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/${payload.projectId}/offer/list`,
		pendingKey: STATE_STORE_KEYS.Offers,
		token: payload.token,
		params: payload.params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiDeleteOffer = (payload: {
  token: IFetchApiProps['token']
  projectId: number
  offerId: number
}) => {
	return fetchApi({
		method: FETCH_API_METHODS.Delete,
		url: `/project/${payload.projectId}/offer/${payload.offerId}/delete`,
		pendingKey: STATE_STORE_KEYS.DeleteOffer,
		token: payload.token
	})
}

export const fetchApiOfferDownload = async (
	payload: {
		token: IFetchApiProps['token']
		projectId: number
		offerId: number
    type?: string
	}
) => {
	if (!payload.token || !payload.offerId) return

	const url = `/project/${payload.projectId}/offer/${payload.offerId}/download`

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url,
		pendingKey: STATE_STORE_KEYS.DownloadOffer,
		token: payload.token,
		params: {
			type: payload.type
		}
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiUploadOffer = ({
	projectId,
	type,
	token,
	payload
}: IFetchApiUploadOffer) => {
	if (!token) return

	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${projectId}/offer/${type}/upload`,
		pendingKey: STATE_STORE_KEYS.UploadOffer,
		token,
		payload
	})
}

export const fetchApiGetOfferNotifyAll = async (
	{
		projectId,
		offerId,
		token
	}: {
		projectId: number
		offerId: number
		token: string
	}
) => {
	if (!token && !offerId && !projectId) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/${projectId}/offer/${offerId}/notify-all`,
		pendingKey: STATE_STORE_KEYS.Offers,
		token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetOfferDetails = async (payload: {
  token: IFetchApiProps['token']
  projectId: number
  offerId: number
}) => {
	if (!payload.token) return

	const response = await fetchApi({
		method: FETCH_API_METHODS.Get,
		url: `/project/${payload.projectId}/offer/${payload.offerId}`,
		pendingKey: STATE_STORE_KEYS.Offers,
		token: payload.token
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGenerateOfferConfirmation = (payload: {
  token: IFetchApiProps['token']
  projectId: number
  payload: components['schemas']['GenerateOfferConfirmation']
}) => {
	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/${payload.projectId}/offer/confirmation/generate`,
		pendingKey: STATE_STORE_KEYS.GenerateOfferConfirmation,
		token: payload.token,
		payload: payload.payload
	})
}
