import type { components } from 'schema.ts'

export enum ICPProductAdditionalAttrs {
  Type = 'Typ',
  Manufacturer = 'Hersteller',
  CertificateNumber = 'Komponentenzertifikat Nr'
}

export interface ICPProductBDTablePropTypes {
  loading?: boolean
  attrsList: components['schemas']['GetListAttributes'][]
  productList: components['schemas']['ListProduct'][]
}
