import type { components } from 'schema.ts'

import type { IFetchApiProps } from '~/portal/api'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api'
import { STATE_STORE_KEYS } from '~/portal/types'

export interface IFetchApiGetChecklistItem {
  token: string
  checklistId: number
  checklistItemId: number
  projectId?: number
  isCustom?: boolean
}
export interface IFetchApiAddParagraph {
  token: IFetchApiProps['token']
  checklistId: number
  checklistItemId: number
  isCustom: boolean
  payload: any
}

export interface IFetchApiAddQuestion {
  token: string
  checklistId: number
  checklistItemId: number
  paragraphId: number
  isCustom: boolean
  data: any
}

export interface IFetchApiDeleteChecklistItemParagraph {
  token: string
  checklistId: number
  checklistItemId: number
  paragraphId: number
  isCustom: boolean
}

export interface IFetchApiAddSubParagraph {
  token: string
  checklistId: number
  checklistItemId: number
  paragraphId: number
  projectId: number
  isCustom: boolean
}

export interface IFetchRemoveSubParagraph {
  token: string
  projectId: number
  checklistId: number
  checklistItemId: number
  paragraphId: number
  subParagraphId: number
  isCustom: boolean
}

export interface IFetchApiDeleteQuestion {
  token: string
  checklistId: number
  checklistItemId: number
  questionId: number
  paragraphId: number
  isCustom: boolean
}

export interface IFetchApiUpdateChecklistItemComment {
  token: string
  projectId: number
  checklistId: number
  checklistItemId: number
  comment: string
  isCustom: boolean
}

export const fetchCreateChecklistItem = async ({ token, checklistId, data }: {
  token: IFetchApiProps['token']
  checklistId: number
  data: {
    name: string
    description: string
  }
}): Promise<components['schemas']['GetChecklistItem'] | undefined> => {
	const url = `/project/checklist/${checklistId}/add-item`
	const response = await fetchApi({
		url,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.AddChecklistItem,
		token,
		payload: data
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchGetChecklistItem = async ({
	token,
	checklistId,
	checklistItemId,
	projectId,
	isCustom
}: IFetchApiGetChecklistItem) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	const projectIdParam = projectId ? `${projectId}/` : ''
	const url = `/project/${projectIdParam}checklist/${checklistId}/${itemType}/${checklistItemId}/paragraph/list`
	const response = await fetchApi({
		url,
		method: FETCH_API_METHODS.Get,
		token,
		pendingKey: STATE_STORE_KEYS.GetChecklistItem
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiGetChecklists = async ({ token }: {
  token: string
  params?: {
    page?: number
    return_all?: boolean
  }
}) => {
	const url = '/project/checklist/list'
	const response = await fetchApi({
		url,
		method: FETCH_API_METHODS.Get,
		token,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiUpdateChecklistItem = async (payload: {
  token: string
  checklistId: number
  checklistItemId: number
  isCustom?: boolean
  data: {
    name?: string
    description?: string
    comment?: string | null
  }
}) => {
	const itemType = payload.isCustom ? 'custom-item' : 'item'
	return fetchApi({
		url: `/project/checklist/${payload.checklistId}/${itemType}/${payload.checklistItemId}/update`,
		method: FETCH_API_METHODS.Patch,
		token: payload.token,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive,
		payload: payload.data
	})
}

export const fetchApiUpdateChecklistItemQuestion = async (payload: {
  token: string
  checklistId: number
  checklistItemId: number
  questionId: number
  paragraphId: number
  isCustom?: boolean
  data: any
}) => {
	const itemType = payload.isCustom ? 'custom-item' : 'item'
	return fetchApi({
		token: payload.token,
		url: `/project/checklist/${payload.checklistId}/${itemType}/${payload.checklistItemId}/paragraph/${payload.paragraphId}/question/${payload.questionId}/update`,
		method: FETCH_API_METHODS.Patch,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive,
		payload: payload.data,
		forceFetch: true
	})
}

export const fetchApiAddChecklistItemQuestion = async ({
	token,
	checklistId,
	checklistItemId,
	paragraphId,
	isCustom,
	data
}: IFetchApiAddQuestion) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	return fetchApi({
		token,
		url: `/project/checklist/${checklistId}/${itemType}/${checklistItemId}/paragraph/${paragraphId}/add-question`,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive,
		payload: data,
		forceFetch: true
	})
}

export const fetchApiDeleteChecklistItemQuestion = async ({
	token,
	checklistId,
	checklistItemId,
	questionId,
	paragraphId,
	isCustom
}: IFetchApiDeleteQuestion) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	return fetchApi({
		token,
		url: `/project/checklist/${checklistId}/${itemType}/${checklistItemId}/paragraph/${paragraphId}/question/${questionId}/delete`,
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive,
		forceFetch: true
	})
}

export const fetchApiAddParagraph = ({
	token,
	checklistId,
	checklistItemId,
	isCustom,
	payload
}: IFetchApiAddParagraph) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	return fetchApi({
		method: FETCH_API_METHODS.Post,
		url: `/project/checklist/${checklistId}/${itemType}/${checklistItemId}/add-paragraph`,
		token,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive,
		payload
	})
}

export const fetchApiUpdateChecklistItemParagraph = async (payload: {
  token: string
  checklistId: number
  checklistItemId: number
  paragraphId: number
  isCustom: boolean
  data: any
}) => {
	const itemType = payload.isCustom ? 'custom-item' : 'item'
	return fetchApi({
		token: payload.token,
		url: `/project/checklist/${payload.checklistId}/${itemType}/${payload.checklistItemId}/paragraph/${payload.paragraphId}/update`,
		method: FETCH_API_METHODS.Patch,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive,
		payload: payload.data,
		forceFetch: true
	})
}

export const fetchApiDeleteChecklistItemParagraph = async ({
	token,
	checklistId,
	checklistItemId,
	paragraphId,
	isCustom
}: IFetchApiDeleteChecklistItemParagraph) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	return fetchApi({
		token,
		url: `/project/checklist/${checklistId}/${itemType}/${checklistItemId}/paragraph/${paragraphId}/remove`,
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive,
		forceFetch: true
	})
}

export const fetchApiAddSubParagraph = async ({
	token,
	checklistId,
	checklistItemId,
	paragraphId,
	projectId,
	isCustom
}: IFetchApiAddSubParagraph) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	return fetchApi({
		token,
		url: `project/${projectId}/checklist/${checklistId}/${itemType}/${checklistItemId}/paragraph/${paragraphId}/duplicate`,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive
	})
}

export const fetchApiRemoveSubParagraph = ({
	token,
	projectId,
	checklistId,
	checklistItemId,
	subParagraphId,
	isCustom
}: IFetchRemoveSubParagraph) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	return fetchApi({
		token,
		url: `/project/${projectId}/checklist/${checklistId}/${itemType}/${checklistItemId}/paragraph/${subParagraphId}/delete`,
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive
	})
}

export const fetchApiUpdateChecklistItemComment = async ({
	token,
	projectId,
	checklistId,
	checklistItemId,
	comment,
	isCustom
}: IFetchApiUpdateChecklistItemComment) => {
	const itemType = isCustom ? 'custom-item' : 'item'
	return fetchApi({
		token,
		url: `/project/${projectId}/checklist/${checklistId}/${itemType}/${checklistItemId}/add-comment`,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ChecklistInteractive,
		payload: {
			comment
		}
	})
}
