<script lang="ts" setup>
import type { ICPCounterPropTypes } from '~/portal/components/base'

defineProps<ICPCounterPropTypes>()
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
  <span
    :class="[
      'cp-counter',
      $attrs.class,
    ]"
  >
		{{ value }}
  </span>
</template>

<style lang="scss">
	.cp-counter {
		font-size: 11px;
		letter-spacing: 0.5px;

		@apply
			inline-flex
			justify-center
			text-white
			text-center
			font-bold

			bg-cp-base-red
			min-w-[19px]
			rounded-xl
			px-1.5
		;
	}
</style>
