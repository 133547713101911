import { defineStore, storeToRefs } from 'pinia'

import type { IFetchApiProps, IFetchApiSetInvoiseStatus } from '~/portal/api'
import {
	fetchApiGetInvoiceList,
	fetchApiPostInvoiceStatus
} from '~/portal/api'
import {
	AccessScopesEnum,
	useAuthData
} from '~/portal/composables'
import { useStateStore } from '~/portal/stores'
import { STATE_STORE_KEYS } from '~/portal/types'

import type { IInvoicesStoreTypes } from './'

const formDataInvoiceState: IInvoicesStoreTypes['currentInvoice']['formData'] = {
	documentName: '',
	projectNumber: undefined,
	customerProjectNumber: undefined,
	personName: '',
	personEmail: '',
	phoneNumber: '',
	companyName: '',
	invoiceType: null,
	service: undefined,
	deliveryDate: undefined,
	prepayment: null,
	certificationPrice: undefined,
	comissioningPrice: undefined,
	thirdProductPrice: undefined,
	vat: undefined,
	paymentTerms: undefined,
	invoiceNumber: undefined
}

export const useInvoicesStore = defineStore('invoicesStore', {
	state: (): IInvoicesStoreTypes => ({
		list: [],
		currentPage: 1,
		resultCount: 0,
		isPagination: true,
		currentInvoice: {
			id: undefined,
			formData: {
				...formDataInvoiceState
			}
		},
		currentInvoiceName: ''
	}),
	getters: {
		getLoadingInvoiceState () {
			const stateStore = useStateStore()
			const { getLoadingStatus } = storeToRefs(stateStore)

			return getLoadingStatus.value(
				STATE_STORE_KEYS.InvoiceInteractive
			)
		},
		getErrorInvoiceMsg () {
			const stateStore = useStateStore()
			const { getErrorStatus } = storeToRefs(stateStore)

			return getErrorStatus.value(
				STATE_STORE_KEYS.InvoiceInteractive
			)
		},
		getAuthHelper () {
			const authData = useAuthData()

			return {
				checkHasScope: authData.checkHasScope
			}
		},
		getAllowGenerate () {
			// @ts-expect-error ___
			return this.getAuthHelper.checkHasScope([
				AccessScopesEnum.PORTAL_TEAM_LEAD,
				AccessScopesEnum.PORTAL_PROJECT_MANAGER
			])
		},
		getAllowDownload () {
			// @ts-expect-error ___
			return this.getAuthHelper.checkHasScope([
				AccessScopesEnum.CLIENT_ADMIN,
				AccessScopesEnum.PORTAL_TEAM_LEAD,
				AccessScopesEnum.PORTAL_PROJECT_MANAGER
			])
		},
		getAllowSendToClient () {
			// @ts-expect-error ___
			return this.getAuthHelper.checkHasScope([
				AccessScopesEnum.PORTAL_TEAM_LEAD,
				AccessScopesEnum.PORTAL_PROJECT_MANAGER
			])
		},
		getAllowUpload () {
			return this.getAllowGenerate
		},
		getAllowChangeStatus () {
			return this.getAllowGenerate
		},
		getAllowEditAndDelete () {
			return this.getAllowGenerate
		},
		getAllowViewDetails () {
			return this.getAllowDownload
		}
	},
	actions: {
		async resetGetInvoiceListParams () {
			this.list = []
			this.currentPage = 1
			this.isPagination = true
		},
		setCurrentInvoice (payload: {
			id: number | string
			formData: IInvoicesStoreTypes['currentInvoice']['formData']
		}) {
			this.currentInvoice = {
				id: payload.id,
				formData: payload.formData
			}
		},
		setCurrentInvoiceName (name: string) {
			this.currentInvoiceName = name
		},
		resetCurrentInvoice () {
			this.currentInvoice = {
				id: undefined,
				formData: {
					...formDataInvoiceState
				}
			}
		},
		resetInvoiceList () {
			this.list = []
			this.isPagination = true
			this.currentPage = 1
			this.resultCount = 0
		},
		async fetchGetInvoiceList (payload: {
			token: IFetchApiProps['token']
			id: number
		}) {
			const response = await fetchApiGetInvoiceList({
				token: payload.token,
				id: payload.id,
				params: {
					page: this.currentPage
				}
			})

			if (response) {
				this.currentPage = response?.next ?? response?.previous ?? 1
				this.resultCount = response.count as number
				// eslint-disable-next-line max-len
				this.isPagination = !!response?.next && !(this.list.length === this.currentPage)

				if (response?.results) {
					if (this.list.length) {
						this.list.push(...response.results)
					} else {
						this.list = response.results
					}
				}
				return response
			}
		},
		async fetchChangeInvoiceStatus (payload: IFetchApiSetInvoiseStatus) {
			const response = await fetchApiPostInvoiceStatus({
				token: payload.token,
				projectId: payload.projectId,
				invoiceId: payload.invoiceId,
				status: payload.status
			})

			if (response) {
				await this.resetGetInvoiceListParams()
				await this.fetchGetInvoiceList({
					token: payload.token,
					id: payload.projectId
				})
				return response
			}
		}
	}
})
