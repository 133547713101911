import type { AxiosError } from 'axios'
import { defineStore } from 'pinia'

import { employeeApi } from '~/quality-management/api/employee-api/employeeApi'
import type {
	IEmployee,
	IEmployeeEditForm,
	IEmployeeTableTab
} from '~/quality-management/api/employee-api/types'
import {
	filesTabItem,
	generalInfoTabItem
} from '~/quality-management/stores/employeeStore/constants'
import type {
	IEmployeeStore
} from '~/quality-management/stores/employeeStore/employeeStoreType'
import {
	useLoaderStore
} from '~/quality-management/stores/loaderStore/useLoaderStore'

export const useEmployeeStore = defineStore('qmEmployeeStore', {
	state: ():IEmployeeStore => ({
		employee: null,
		tableData: null,
		lastSelectedYear: new Date().getFullYear(),
		editEmployeeForm: {
			name: '',
			abbreviation: '',
			entryDate: '',
			endDate: '',
			position: '',
			certifier: null,
			departmentIds: [],
			workLocation: '',
			diploma: '',
			previousActivities: '',
			blockings: '',
			blockPeriod: ''
		},
		departments: []
	}),
	actions: {
		async fetchEmployee (userId: number): Promise<IEmployee | AxiosError | null> {
			const loaderStore = useLoaderStore()
			loaderStore.toggleIsLoading()

			try {
				const res = await employeeApi.getEmployee(userId)
				this.employee = res.data
				const data = res.data
				this.editEmployeeForm = {
					name: data.firstName + ' ' + data.lastName,
					abbreviation: data.abbreviation,
					certifier: data.certifier,
					entryDate: data.entryDate,
					endDate: data.endDate,
					position: data.position,
					departmentIds: data.departments.map(item => item.id),
					workLocation: data.workLocation,
					diploma: data.diploma,
					previousActivities: data.previousActivities,
					blockings: data.blockings || '',
					blockPeriod: data.blockPeriod || ''

				}
				return res.data
			} catch (error: unknown) {
				const axiosError = error as AxiosError

				if (axiosError.response?.status === 404) {
					return null
				}

				throw error
			} finally {
				loaderStore.toggleIsLoading()
			}
		},
		async fetchTableData (userId: number, tableId: number, year: number) {
			const loaderStore = useLoaderStore()
			loaderStore.toggleIsLoading()

			try {
				const res = await employeeApi.getTableData(userId, tableId, year)
				this.tableData = res.data

				return res.data
			} catch (error) {
				console.error(error)
				return null
			} finally {
				loaderStore.toggleIsLoading()
			}
		},
		async patchTableData (userId: number, tableId: number, body: any) {
			const loaderStore = useLoaderStore()
			loaderStore.toggleIsLoading()
			try {
				const res = await employeeApi.patchTableData(userId, tableId, body)
				this.tableData = res.data
				return res.data
			} catch (error) {
				console.error(error)
				throw error
			} finally {
				loaderStore.toggleIsLoading()
			}
		},
		async fetchDepartments () {
			const loaderStore = useLoaderStore()
			loaderStore.toggleIsLoading()
			try {
				const res = await employeeApi.getDepartments()
				this.departments = res.data ? res.data : []
			} catch (error) {
				console.error(error)
				throw error
			} finally {
				loaderStore.toggleIsLoading()
			}
		},

		async editEmployeeData (userId: number, employeeData: IEmployeeEditForm) {
			const loaderStore = useLoaderStore()
			loaderStore.toggleIsLoading()
			try {
				const res = await employeeApi.patchEmployeeInfo(userId, employeeData)
				if (res.message === 'Ok') {
					this.fetchEmployee(userId)
					return res
				} else return null
			} catch (error) {
				console.log(error)
			} finally { loaderStore.toggleIsLoading() }
		},
		setEditFormInitialState () {
			if (this.employee) {
				const initialState = {
					name: this.employee.firstName + ' ' + this.employee.lastName,
					abbreviation: this.employee.abbreviation,
					certifier: this.employee.certifier,
					entryDate: this.employee.entryDate,
					endDate: this.employee.endDate,
					position: this.employee.position,
					departmentIds: this.employee.departments.map(item => item.id),
					workLocation: this.employee.workLocation,
					diploma: this.employee.diploma,
					previousActivities: this.employee.previousActivities,
					blockings: this.employee.blockings || '',
					blockPeriod: this.employee.blockPeriod || ''
				}
				this.editEmployeeForm = initialState
			}
		}
	},
	getters: {
		getEmployeeTabs (state: IEmployeeStore) {
			const tabItems = state.employee?.tables.map((item: IEmployeeTableTab) => ({
				title: item.name,
				value: item.type + '-' + item.id
			}))

			return tabItems ? [generalInfoTabItem, ...tabItems, filesTabItem] : []
		}
	}
})
