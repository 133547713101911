import { defineStore } from 'pinia'

import type {
	IFetchApiAddSubParagraph,
	IFetchApiGetChecklistItem,
	IFetchRemoveSubParagraph
} from '~/portal/api'
import {
	fetchApiAddSubParagraph,
	fetchApiRemoveSubParagraph,
	fetchGetChecklistItem
} from '~/portal/api'

import type {
	IDocumentCheckingStoreTypes
} from './documentCheckingStoreTypes.ts'

export const useDocumentCheckingStore = defineStore('document-checking', {
	state: (): IDocumentCheckingStoreTypes => ({
		checklistItem: {}
	}),
	actions: {
		async fetchQuestions (payload: IFetchApiGetChecklistItem
		) {
			if (
				!payload.token ||
        !payload.projectId ||
        !payload.checklistId ||
        !payload.checklistItemId
			) return

			const response = await fetchGetChecklistItem(payload)

			if (response) {
				this.checklistItem = response
			}
		},
		async addSubParagraph (payload: IFetchApiAddSubParagraph) {
			const reposnse = await fetchApiAddSubParagraph(payload)

			if (reposnse?.data) {
				const index = this.checklistItem.paragraphs
					.findIndex((paragraph: any) => {
						return paragraph.id === payload.paragraphId
					})

				if (index > -1) {
					this.checklistItem.paragraphs[index].plates.push(reposnse.data)
				}
			}
		},
		async removeSubParagraph (payload: IFetchRemoveSubParagraph) {
			const response = await fetchApiRemoveSubParagraph(payload)

			if (response) {
				const paragraphIndex = this.checklistItem.paragraphs.findIndex((paragraph: any) => {
					return paragraph.id === payload.paragraphId
				})

				if (paragraphIndex > -1) {
					const subParagraphIndex = this.checklistItem.paragraphs[paragraphIndex].plates.findIndex((subParagraph: any) => {
						return subParagraph.id === payload.subParagraphId
					})

					if (subParagraphIndex > -1) {
						this.checklistItem.paragraphs[paragraphIndex].plates.splice(subParagraphIndex, 1)
					}
				}
			}
		}
	}
})
