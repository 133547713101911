import { defineStore } from 'pinia'

import type {
	IFetchApiControllerList,
	IFetchControllerTypeList
} from '~/portal/api'
import {
	fetchApiControllerList,
	fetchControllerTypeList
} from '~/portal/api'
import type { IPControllersStoreTypes } from '~/portal/stores'

export const useControllersStore = defineStore('controllersStore', {
	state: (): IPControllersStoreTypes => ({
		controllerList: [],
		controllerTypeList: []
	}),
	actions: {
		async fetchControllerList (
			payload: IFetchApiControllerList
		) {
			const response = await fetchApiControllerList(payload)

			if (response && response.data) {
				this.controllerList = response.data
			}
		},
		async fetchControllerTypeList (
			payload: IFetchControllerTypeList
		) {
			const response = await fetchControllerTypeList(payload)

			if (response && response.data) {
				this.controllerTypeList = response.data
			}
		}
	}
})
