import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { MembersAndTeamsRoutesNamesEnum } from '.'

export const getMembersAndTeamsRoutes = (app?: App): RouteRecordRaw => {
	return {
		path: '/members-and-teams',
		name: MembersAndTeamsRoutesNamesEnum.MembersAndTeams,
		component: () => import('~/portal/pages/members-and-teams/index.vue'),
		beforeEnter: createAuthGuard(app as any),
		redirect: { name: MembersAndTeamsRoutesNamesEnum.Members },
		children: [
			{
				path: 'members',
				name: MembersAndTeamsRoutesNamesEnum.Members,
				component: () => import('~/portal/pages/members-and-teams/members.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'teams',
				name: MembersAndTeamsRoutesNamesEnum.Teams,
				component: () => import('~/portal/pages/members-and-teams/teams.vue'),
				beforeEnter: createAuthGuard(app as any)
			}
		]
	}
}
