import { defineStore } from 'pinia'

import type {
	IFetchApiChatAllMessageCount,
	IFetchApiChatMessageAdd,
	IFetchApiChatMessageEmailNotify,
	IFetchApiChatMessageMarkAsRead,
	IFetchApiChatNoteAdd,
	IFetchApiChatNoteDelete,
	IFetchApiChatNoteUpdate,
	IFetchApiChatPayload
} from '~/portal/api'
import {
	fetchApiChatAllMessageCount,
	fetchApiChatList,
	fetchApiChatMessageAdd,
	fetchApiChatMessageCount,
	fetchApiChatMessageEmailNotify,
	fetchApiChatMessageMarkAsRead,
	fetchApiChatNoteAdd,
	fetchApiChatNoteDelete,
	fetchApiChatNoteList,
	fetchApiChatNoteSend,
	fetchApiChatNoteUpdate
} from '~/portal/api'
import type { IChatStore } from '~/portal/stores'

import type { components } from 'schema.ts'

export const useChatStore = defineStore('chatStore', {
	state: (): IChatStore => ({
		countAll: {
			notifications: 0,
			messages: 0,
			projects: []
		},
		count: {},
		noteList: {},
		chatList: {},
		loading: {}
	}),
	getters: {
		messageCount (state: IChatStore) {
			return (projectId: string) => state.count[projectId]?.messages || 0
		},
		noteListSorted (state: IChatStore) {
			return (projectId: string): components['schemas']['ListNote'][] => {
				return state.noteList[projectId]?.sort((a, b) => {
					const dateA = a.created_at ? new Date(a.created_at) : new Date()
					const dateB = b.created_at ? new Date(b.created_at) : new Date()

					return dateA.getTime() - dateB.getTime()
				})
			}
		},
		chatListSorted (state: IChatStore) {
			return (projectId: string): components['schemas']['ListChatMessages'][] => {
				return state.chatList[projectId]?.sort((a, b) => {
					const dateA = a.created_at ? new Date(a.created_at) : new Date()
					const dateB = b.created_at ? new Date(b.created_at) : new Date()

					return dateA.getTime() - dateB.getTime()
				})
			}
		},
		notificationAllAmount (state: IChatStore) {
			return state.countAll.notifications
		},
		chatAllMessageAmount (state: IChatStore) {
			return state.countAll.messages
		}
	},
	actions: {
		async fetchChatList (
			payload: IFetchApiChatPayload
		) {
			if (this.loading[payload.projectId]) {
				return
			}

			let response

			try {
				this.loading[payload.projectId] = true
				response = await fetchApiChatList(payload)
			} finally {
				this.loading[payload.projectId] = false
			}

			if (response && response.data) {
				this.chatList[payload.projectId] = response.data
			}
		},
		async fetchChatMessageAdd (
			payload: IFetchApiChatMessageAdd
		) {
			await fetchApiChatMessageAdd(payload)
			await this.fetchChatList({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchChatMessageMarkAsRead (
			payload: IFetchApiChatMessageMarkAsRead
		) {
			await fetchApiChatMessageMarkAsRead(payload)
			await this.fetchChatMessageCount({
				token: payload.token,
				projectId: payload.projectId
			})
			await this.fetchChatList({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchChatMessageCount (
			payload: IFetchApiChatPayload
		) {
			const response = await fetchApiChatMessageCount(payload)

			if (response && response.data) {
				this.count[payload.projectId] = response.data
			}

			return response?.data
		},
		async fetchApiChatAllMessageCount (
			payload: IFetchApiChatAllMessageCount
		) {
			const response = await fetchApiChatAllMessageCount(payload)

			if (response) {
				this.countAll = response.data
			}
		},
		async fetchChatNoteList (
			payload: IFetchApiChatPayload
		) {
			const response = await fetchApiChatNoteList(payload)

			if (response && response.data) {
				this.noteList[payload.projectId] = response.data
			}
		},
		async fetchApiChatNoteAdd (
			payload: IFetchApiChatNoteAdd
		) {
			await fetchApiChatNoteAdd(payload)
			await this.fetchChatNoteList({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchApiChatNoteDelete (
			payload: IFetchApiChatNoteDelete
		) {
			await fetchApiChatNoteDelete(payload)
			await this.fetchChatNoteList({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchApiChatNoteSend (
			payload: IFetchApiChatNoteDelete
		) {
			await fetchApiChatNoteSend(payload)
			await this.fetchChatNoteList({
				token: payload.token,
				projectId: payload.projectId
			})
			await this.fetchChatList({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchChatNoteUpdate (
			payload: IFetchApiChatNoteUpdate
		) {
			await fetchApiChatNoteUpdate(payload)
			await this.fetchChatNoteList({
				token: payload.token,
				projectId: payload.projectId
			})
		},
		async fetchApiChatMessageEmailNotify (
			payload: IFetchApiChatMessageEmailNotify
		) {
			await fetchApiChatMessageEmailNotify(payload)
			await this.fetchChatList({
				token: payload.token,
				projectId: payload.projectId
			})
		}
	}
})
