import { defineStore } from 'pinia'

import {
	fetchApiCreateTeam,
	fetchApiDeleteMember,
	fetchApiDeleteTeam,
	fetchApiGetMembersListByCompany,
	fetchApiGetTeamsList,
	fetchApiReInviteMember,
	fetchCancelInvitation,
	fetchInviteMembers,
	fetchPatchEditTeamName,
	fetchPatchUpdateUserById,
	fetchPostReassignProjectToMember,
	fetchPostReassignProjectToTeam
} from '~/portal/api'
import { ECP_MEMBER_STATUSES } from '~/portal/components'
import { AccessScopesEnum } from '~/portal/composables'

import type { IMembersTeamsStoreTypes } from './membersTeamsStoreTypes.ts'

export const useMembersTeamsStore = defineStore('membersTeamsStore', {
	state: (): IMembersTeamsStoreTypes => ({
		members: [],
		teams: [],
		isPagination: false,
		currentPage: 1,
		pageCount: 0,
		allMembers: [],
		allTeams: []
	}),
	actions: {
		resetPagination () {
			this.currentPage = 1
			this.pageCount = 0
			this.isPagination = false
		},
		resetMembers () {
			this.members = []
			this.resetPagination()
		},
		resetTeams () {
			this.teams = []
			this.resetPagination()
		},
		async fetchMembersList (token: string, search?: string) {
			const params = {
				page: this.currentPage,
				search
			}
			const response = await fetchApiGetMembersListByCompany({ token, params })
			if (response) {
				this.pageCount = response.count
				this.currentPage = response.next ??
          (response.previous + 1) ??
          1
				this.members?.push(...response.results)
				this.isPagination = !!response.next
			}
		},
		async fetchTeams (token: string, search?: string) {
			const params = {
				page: this.currentPage,
				search
			}
			const response = await fetchApiGetTeamsList({ token, params })
			if (response) {
				this.pageCount = response.count
				this.currentPage = response.next ??
          (response.previous + 1) ??
          1
				this.teams?.push(...response.results)
				this.isPagination = !!response.next
			}
		},
		async deleteMember (payload: { token: string; id: number }) {
			await fetchApiDeleteMember(payload)
			this.members = this.members?.filter(
				(member) => member.id !== payload.id
			)
			this.pageCount -= 1
		},
		async reassignProjects (payload: {
      token: string
      projects: number[]
      id: number
      type: 'member' | 'team'
      user: number
    }) {
			if (payload.type === 'member') {
				await fetchPostReassignProjectToMember({
					token: payload.token,
					projects: payload.projects,
					user: payload.user
				})
			} else {
				await fetchPostReassignProjectToTeam({
					token: payload.token,
					projects: payload.projects,
					newTeamId: payload.user
				})
			}
		},
		async cancelInvitation (token: string, id: number) {
			await fetchCancelInvitation({ token, id })
			this.members = this.members?.filter(member => member.id !== id) || []
			this.pageCount -= 1
		},
		async inviteMembers (payload: {
      token: string
      emails: string[]
      teamId?: number
      allowCommercialInfoAccess: boolean
    }) {
			await fetchInviteMembers(payload)
			await this.resetMembers()
			await this.fetchMembersList(payload.token)
		},
		async createTeam (payload: {
      token: string
      name: string
      members: number[]
    }) {
			await fetchApiCreateTeam(payload)
			await this.resetTeams()
			await this.fetchTeams(payload.token)
		},
		async deleteTeam (payload: { token: string; id: number }) {
			await fetchApiDeleteTeam(payload)
			this.teams = this.teams?.filter(team => team.id !== payload.id) || []
		},
		async editTeam (payload: {
      token: string
      id: number
      name: string
    }) {
			await fetchPatchEditTeamName(payload)
			const index = this.teams?.findIndex(team => team.id === payload.id)
			if (index !== undefined && index !== -1 && this.teams) {
				this.teams[index].name = payload.name
			}
		},
		async changeMemberStatus (payload: {
      token: string
      id: number
      status: ECP_MEMBER_STATUSES.Active | ECP_MEMBER_STATUSES.Blocked
    }) {
			const response = await fetchPatchUpdateUserById({
				token: payload.token,
				id: payload.id,
				user: {
					is_user_active: payload.status === ECP_MEMBER_STATUSES.Active
				}
			})

			const member = this.members?.find(
				member => member.id === payload.id
			)

			if (response && member) {
				member.status = payload.status
			}
		},
		async resendInvitation (payload: {
      token: string
      id: number
    }) {
			const memberIndex = this.members?.findIndex(
				(member) => member.id === payload.id
			)
			if (
				this.members &&
        memberIndex !== undefined &&
        memberIndex !== -1
			) {
				const response = await fetchApiReInviteMember({
					token: payload.token,
					email: this.members[memberIndex].email
				})

				if (response) {
					this.members[memberIndex].status = ECP_MEMBER_STATUSES.Invited
				}
			}
		},
		async fetchAllMembers (token: string) {
			const response = await fetchApiGetMembersListByCompany({
				token,
				params: {
					return_all: true,
					is_blocked: false,
					role: AccessScopesEnum.CLIENT_EMPLOYEE
				}
			})

			if (response) {
				this.allMembers = response
			}
		},
		async fetchAllTeams (token: string) {
			const response = await fetchApiGetTeamsList({
				token,
				params: { return_all: true }
			})

			if (response) {
				this.allTeams = response
			}
		}
	}
})
