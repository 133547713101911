import type {
	ICPFileUploadPropTypes,
	ICPTextEditorPropTypes,
	ICPTextFieldPropTypes
} from '~/portal/components/base'

export enum VOLTAGE_LVL {
  Medium = 'MEDIUM_VOLTAGE',
  High = 'HIGH_VOLTAGE',
  ExtraHigh = 'EXTRA_HIGH_VOLTAGE'
}

export enum GENDER {
  Male = 'Male',
  Female = 'Female',
  Diverse = 'Diverse'
}

export enum CERTIFICATION {
  PlantCertificate = 'plant_certificate',
  ConformityCheck = 'conformity_check'
}
export interface ICPFormCertificationFormData {
  personal: ICPTextFieldPropTypes['modelValue']
  consumption: ICPTextFieldPropTypes['modelValue']
  contactPerson: ICPTextFieldPropTypes['modelValue']
  companyName: ICPTextFieldPropTypes['modelValue']
  phoneNumber: ICPTextFieldPropTypes['modelValue']
	email: ICPTextFieldPropTypes['modelValue']
  city: ICPTextFieldPropTypes['modelValue']
  street: ICPTextFieldPropTypes['modelValue']
  file: ICPFileUploadPropTypes['modelValue']
  voltage: VOLTAGE_LVL
  productType: CERTIFICATION[]
  comment: ICPTextEditorPropTypes['modelValue']
  gender: GENDER | null
}

export interface ICPFormCertificationInfoPropTypes {
  formData: ICPFormCertificationFormData
  disabled?: boolean
}
