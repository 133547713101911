/* eslint-disable simple-import-sort/imports */
import type { components } from 'schema.ts'

import { useDateFormat } from '@vueuse/core'

import type { ICPEZETableItemTypes, ICPEZETablePropTypes, ICPInvoice } from '~/portal/components'
import { ECP_EZE_TABLE_HEADING_KEYS, ECP_EZE_TABLE_KEYS } from '~/portal/components'
import type {
	ICPCompanyChecklistItemsTableRow,
	ICPCompanyChecklistsTableRow,
	ICPCompanyEmployeesTableRow,
	ICPCompanyInvoicesTableData,
	ICPDocumentsChecklistTableData,
	ICPEzeProductTableRow,
	ICPInvoicesTablePropTypes,
	ICPOfferDocumentsTableItem,
	ICPProductTableData,
	ICPProjectControllersTableRow,
	ICPReportsTableRow,
	ICPTableClientsPropTypes,
	ICPTableMembersPropTypes,
	ICPTeamsTableRow,
	TCPInvoiceStatus
} from '~/portal/components/pages'
import {
	ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS,
	ECP_COMPANY_CHECKLIST_ITEMS_TABLE_COLUMNS,
	ECP_COMPANY_EMPLOYEES_STATUS,
	ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS,
	ECP_COMPANY_INVOICES_TABLE_COLUMNS,
	ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS,
	ECP_EZE_PRODUCT_TABLE_COLUMNS,
	ECP_INVOICES_TABLE_KEYS,
	ECP_MEMBERS_TABLE_KEYS,
	ECP_OFFER_DOCUMENTS_TABLE_KEYS,
	ECP_PRODUCT_TABLE_COLUMNS,
	ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS,
	ECP_QUESTION_STATUS,
	ECP_REPORTS_TABLE_COLUMNS,
	ECP_TABLE_CLIENTS_KEYS,
	ECP_TEAMS_TABLE_COLUMNS
} from '~/portal/components/pages'

export const mapEZETableItemToApi = (
	data: ICPEZETableItemTypes
): components['schemas']['CreateUnit'] => {
	return {
		amount: +data[ECP_EZE_TABLE_HEADING_KEYS.Qty],
		energy_system: data[ECP_EZE_TABLE_HEADING_KEYS.Energy_System],
		ibn_date: data[ECP_EZE_TABLE_HEADING_KEYS.IBN_Date],
		manufacturer: data[ECP_EZE_TABLE_HEADING_KEYS.Manufacturer],
		output: Number(data[ECP_EZE_TABLE_HEADING_KEYS.Output_kWel].replace(',', '.')),
		type: data[ECP_EZE_TABLE_HEADING_KEYS.Type],
		total_power: +data[ECP_EZE_TABLE_HEADING_KEYS.Total_power]
	}
}

export const mapEZETableToApi = (
	data: ICPEZETablePropTypes['modelValue']
): components['schemas']['CreateUnit'][] => {
	if (!data.length) return []

	return data.map((table) => ({ ...mapEZETableItemToApi(table) }))
}

export const mapEZETableItemFromApi = ({
	data,
	key
}: {
  data: components['schemas']['GetUnit'][]
  key: ECP_EZE_TABLE_KEYS
}): ICPEZETableItemTypes[] => {
	if (!data) return []
	return data
		.filter((unit) => (unit.unit_state as ECP_EZE_TABLE_KEYS) === key)
		.map((item) => ({
			[ECP_EZE_TABLE_HEADING_KEYS.Qty]: +item.amount,
			[ECP_EZE_TABLE_HEADING_KEYS.Energy_System]: item.energy_system,
			[ECP_EZE_TABLE_HEADING_KEYS.IBN_Date]: item.ibn_date,
			[ECP_EZE_TABLE_HEADING_KEYS.Manufacturer]: item.manufacturer,
			[ECP_EZE_TABLE_HEADING_KEYS.Output_kWel]: item.output.toString(),
			[ECP_EZE_TABLE_HEADING_KEYS.Type]: item.type,
			[ECP_EZE_TABLE_HEADING_KEYS.Total_power]: +item.total_power,
			[ECP_EZE_TABLE_HEADING_KEYS.Action]: item.id
		}))
}

export const mapEZETablesFromApi = (payload: {
  data: components['schemas']['GetScope']['units']
}) => {
	return {
		[ECP_EZE_TABLE_KEYS.New]: [
			...mapEZETableItemFromApi({
				data: payload?.data,
				key: ECP_EZE_TABLE_KEYS.New
			})
		],
		[ECP_EZE_TABLE_KEYS.Old]: mapEZETableItemFromApi({
			data: payload?.data,
			key: ECP_EZE_TABLE_KEYS.Old
		}),
		[ECP_EZE_TABLE_KEYS.Moderate]: mapEZETableItemFromApi({
			data: payload?.data,
			key: ECP_EZE_TABLE_KEYS.Moderate
		})
	}
}

export const mapInvoiceTableFromApi = ({
	data,
	isDropdown,
	hideAction
}: {
  data: any[]
  isDropdown: boolean
	hideAction?: boolean
}): ICPInvoicesTablePropTypes['data'] => {
	if (!data) return []
	const renderType = (type: any) => {
		switch (type) {
		case 'PREPAYMENT':
			return 'Vorauszahlung'
		case 'MAIN_PAYMENT':
			return 'Hauptzahlung'
		case 'ADDITIONAL_PAYMENT':
			return 'Zusätzliche Zahlung'
		case 'StornorechnungPostpayment':
			return 'Stornorechnung Hauptzahlung'
		case 'StornorechnungPrepayment':
			return 'Stornorechnung Vorauszahlung'
		case 'StornorechnungAdditionalPayment':
			return 'Stornorechnung Zusätzliche Zahlung'
		default:
			return '-'
		}
	}

	const renderStatus = (type: any): TCPInvoiceStatus => {
		if (type === 'PAID') {
			return 'Paid'
		} else if (type === 'UNPAID') {
			return 'Unpaid'
		} else if (type === 'STORNO') {
			return 'Canceled'
		} else {
			return 'Irrelevant'
		}
	}

	return data.map((item) => {
		const result: ICPInvoice = {
			[ECP_INVOICES_TABLE_KEYS.Price_value]: mapPriceFormat(item.price),
			[ECP_INVOICES_TABLE_KEYS.Type]: renderType(item.type),
			[ECP_INVOICES_TABLE_KEYS.Service]: item?.services?.map(
				(item: any) => item.name
			).join(', '),
			[ECP_INVOICES_TABLE_KEYS.Data_and_time]: item.payment_terms
				? useDateFormat(item.payment_terms, 'DD.MM.YYYY').value
				: '-',
			[ECP_INVOICES_TABLE_KEYS.Status]: isDropdown
				? {
					value: renderStatus(item.status),
					model: false
				}
				: renderStatus(item.status),
			[ECP_INVOICES_TABLE_KEYS.ExpiredDeadline]:
			new Date() >= new Date(item.payment_terms) && item.status === 'UNPAID'
		}

		if (!hideAction) {
			result[ECP_INVOICES_TABLE_KEYS.Actions] = {
				projectId: item.project.id,
				invoiceId: item.id
			}
		}

		return result
	})
}

export const mapTableClientsFromApi = (payload: {
  data: components['schemas']['ListClients'][]
}): ICPTableClientsPropTypes['modelValue'] => {
	if (!payload.data?.length) return []

	return payload.data.map((item) => ({
		[ECP_TABLE_CLIENTS_KEYS.Name]: item.full_name,
		[ECP_TABLE_CLIENTS_KEYS.Email]: item.email,
		[ECP_TABLE_CLIENTS_KEYS.Phone_number]: item.phone_number || '-',
		[ECP_TABLE_CLIENTS_KEYS.Trust_level]: item.trust_lvl
			? item.trust_lvl === 'HIGH'
				? 'High'
				: item.trust_lvl === 'MEDIUM'
					? 'Medium'
					: 'Low'
			: '-',
		[ECP_TABLE_CLIENTS_KEYS.Projects]: `
      ${item.projects.all}/${item.projects.active}
    `,
		[ECP_TABLE_CLIENTS_KEYS.Project_activity]: item.project_activity
			? useDateFormat(item.project_activity.created_at, 'DD.MM.YYYY HH:mm')
				.value
			: '-',
		[ECP_TABLE_CLIENTS_KEYS.Comment]: item?.comment || '-',
		[ECP_TABLE_CLIENTS_KEYS.Id]: item.company_id || 0
	}))
}

export const mapTableDocumentsChecklistFromApi = (
	paylaod: any[]
): ICPDocumentsChecklistTableData[] => {
	const mapUpdatedBy = (item: any) => {
		switch (item.last_doc_status) {
		case ECP_QUESTION_STATUS.Unchecked:
			return item.answered_by?.name || item.answered_by?.email || ''
		case ECP_QUESTION_STATUS.Checked:
			return item.checked_by?.name || item.checked_by?.email || ''
		case ECP_QUESTION_STATUS.Returned:
			return item.checked_by?.name || item.checked_by?.email || ''
		default:
			return ''
		}
	}

	return paylaod.map((item) => {
		const updatedAt = item.last_update ? useDateFormat(item.last_update, 'DD.MM.YYYY HH:mm').value : '-'
		return {
			[ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.DocumentName]: item.name,
			[ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.LastUpdate]: {
				updatedAt,
				updatedBy: mapUpdatedBy(item)
			},
			[ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.Comment]: item.comment || '-',
			[ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.Iteration]: item.iteration,
			[ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.Status]: item.last_doc_status,
			[ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.IsHidden]: item.is_hidden,
			[ECP_DOCUMENTS_CHECKLIST_TABLE_COLUMNS.Action]: {
				id: item.id,
				isCustom: item.is_custom,
				isHidden: item.is_hidden
			}
		}
	})
}

export const mapTableCompanyChecklistItemsFromApi = (
	payload: any
): ICPCompanyChecklistItemsTableRow[] => {
	return payload.map((item: any) => ({
		[ECP_COMPANY_CHECKLIST_ITEMS_TABLE_COLUMNS.ChecklistItemName]: item.name,
		[ECP_COMPANY_CHECKLIST_ITEMS_TABLE_COLUMNS.Questions]: item.amount_of_questions || 0,
		[ECP_COMPANY_CHECKLIST_ITEMS_TABLE_COLUMNS.LastUpdate]: {
			updatedAt: item.last_update
				? useDateFormat(item.last_update, 'DD.MM.YYYY HH:mm').value
				: '-',
			updatedBy: item.changed_by?.name || item.changed_by?.email || ''
		},
		[ECP_COMPANY_CHECKLIST_ITEMS_TABLE_COLUMNS.Actions]: item.id
	}))
}

export const mapTableCompanyChecklistsFromApi = (
	payload: any
): ICPCompanyChecklistsTableRow[] => {
	return payload.map((item: any) => ({
		[ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.Id]: item.id,
		[ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.ChecklistName]: item.name,
		[ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.UsedInProjects]: item.amount_of_projects,
		[ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.ListItems]: item.items?.length,
		[ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.Questions]: item.amount_of_questions,
		[ECP_COMPANY_CHECKLISTS_TABLE_COLUMNS.LastUpdate]: item.last_update
			? useDateFormat(item.last_update, 'DD.MM.YYYY HH:mm').value
			: '-'
	}))
}

export const mapTableMembersFromApi = (
	payload: any
): ICPTableMembersPropTypes['modelValue'] => {
	return payload.map((item: any) => ({
		[ECP_MEMBERS_TABLE_KEYS.Name]: item.name,
		[ECP_MEMBERS_TABLE_KEYS.Email]: item.email,
		[ECP_MEMBERS_TABLE_KEYS.Status]: item.status,
		[ECP_MEMBERS_TABLE_KEYS.Projects]:
      item.projects?.length ||
      item.project_num ||
      '-',
		[ECP_MEMBERS_TABLE_KEYS.Actions]: {
			id: item.id,
			status: item.status
		}
	}))
}

export const mapTableTeamsFromApi = (
	payload: components['schemas']['PaginatedGetTeamList']['results']
): ICPTeamsTableRow[] => {
	return payload?.map((item: any) => ({
		[ECP_TEAMS_TABLE_COLUMNS.Name]: item.name,
		[ECP_TEAMS_TABLE_COLUMNS.Members]: item.members.map((member: any) => ({
			id: member.id,
			icon: member.image,
			initials: member.name
				.split(' ')
				.map((name: string) => name[0])
				.join('')
		})),
		[ECP_TEAMS_TABLE_COLUMNS.LastActivity]: item.last_activity
			? useDateFormat(item.last_activity, 'DD.MM.YYYY HH:mm').value
			: '-',
		[ECP_TEAMS_TABLE_COLUMNS.Projects]: item.project_num,
		[ECP_TEAMS_TABLE_COLUMNS.Actions]: item.id
	})) || []
}

export const mapPriceFormat = (price: string | number) => {
	return Number(price).toLocaleString('de-DE', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})
}

export const mapCompanyInvoicesFromApi = (
	payload: components['schemas']['GetInvoice'][]
): ICPCompanyInvoicesTableData[] => {
	const invoiceTypes = {
		PREPAYMENT: 'Vorauszahlung',
		MAIN_PAYMENT: 'Hauptzahlung',
		ADDITIONAL_PAYMENT: 'Zusätzliche Zahlung',
		StornorechnungPostpayment: 'Stornorechnung Hauptzahlung',
		StornorechnungPrepayment: 'Stornorechnung Vorauszahlung',
		StornorechnungAdditionalPayment: 'Stornorechnung Zusätzliche Zahlung'
	}

	return payload.map((item: components['schemas']['GetInvoice']) => ({
		[ECP_COMPANY_INVOICES_TABLE_COLUMNS.DocumentName]: item.number || '-',
		[ECP_COMPANY_INVOICES_TABLE_COLUMNS.ProjectName]: item.project?.name || '-',
		[ECP_COMPANY_INVOICES_TABLE_COLUMNS.Managers]: item.managers
			.map((manager) => manager.name || manager.email)
			.join(', ') || '-',
		[ECP_COMPANY_INVOICES_TABLE_COLUMNS.InvoiceType]: item.type ? invoiceTypes[item.type] : '-',
		[ECP_COMPANY_INVOICES_TABLE_COLUMNS.Price]: `${item.price ? mapPriceFormat(item.price) : '0.00'} €`,
		[ECP_COMPANY_INVOICES_TABLE_COLUMNS.Service]: item.services
			.map((service: any) => service.name)
			.join(', '),
		[ECP_COMPANY_INVOICES_TABLE_COLUMNS.Date]: useDateFormat(item.payment_terms, 'DD.MM.YYYY').value,
		[ECP_COMPANY_INVOICES_TABLE_COLUMNS.Status]: {
			name: item.status || null,
			invoiceId: item.id,
			projectId: item.project.id
		},
		[ECP_COMPANY_INVOICES_TABLE_COLUMNS.Actions]: {
			invoiceId: item.id,
			projectId: item.project.id,
			name: item.number
		}
	}))
}

const mapStatus = (status: string) => {
	switch (status) {
	case 'ACTIVE':
		return ECP_COMPANY_EMPLOYEES_STATUS.Active
	case 'BLOCKED':
		return ECP_COMPANY_EMPLOYEES_STATUS.Blocked
	case 'INVITED':
		return ECP_COMPANY_EMPLOYEES_STATUS.Invited
	case 'INVITATION_EXPIRED':
		return ECP_COMPANY_EMPLOYEES_STATUS.InvitationExpired
	default:
		return '-'
	}
}

export const mapCompanyEmployeesFromApi = (
	payload: components['schemas']['ListEmployee'][]
): ICPCompanyEmployeesTableRow[] => {
	return payload.map((item) => {
		return {
			[ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Name]: item.full_name,
			[ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Email]: item.email,
			[ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Role]: item.role?.name,
			[ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Status]: mapStatus(item.status),
			[ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Projects]: item.projects_count || 0,
			[ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.LastActivity]: item.last_activity?.created_at
				? useDateFormat(item.last_activity.created_at, 'DD.MM.YYYY HH:mm').value
				: '-',
			[ECP_COMPANY_EMPLOYEES_TABLE_COLUMNS.Actions]: {
				status: item.status,
				id: item.id
			}
		}
	})
}

export const mapAdditionalProductFromApi = (
	payload: any
): ICPProductTableData[] => {
	return payload.map((item: any) => ({
		[ECP_PRODUCT_TABLE_COLUMNS.Manufacture]: item.manufacturer,
		[ECP_PRODUCT_TABLE_COLUMNS.Type]: item.type,
		[ECP_PRODUCT_TABLE_COLUMNS.LastUpdate]: item.updated_at
			? useDateFormat(item.updated_at, 'DD.MM.YYYY HH:mm').value
			: '-',
		[ECP_PRODUCT_TABLE_COLUMNS.Action]: item.id
	}))
}

export const mapEzeProductFromApi = (
	paylaod: components['schemas']['ListProjectProduct']
): ICPEzeProductTableRow[] => {
	return [
		{
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Description]: 'Name des EZE',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Value]: paylaod.name,
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.CustomValue]: '-',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.LastUpdate]: '-',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Action]: null
		},
		{
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Description]: 'Hersteller des EZE',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Value]: paylaod.manufacturer.name,
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.CustomValue]: '-',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.LastUpdate]: '-',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Action]: null
		},
		{
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Description]: 'Typ der EZE',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Value]: paylaod.type.name,
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.CustomValue]: '-',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.LastUpdate]: '-',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Action]: null
		},
		{
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Description]: 'Anteilschein Nr.',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Value]: paylaod.certificate_number,
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.CustomValue]: '-',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.LastUpdate]: '-',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Action]: null
		},
		{
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Description]: 'Menge',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Value]: paylaod.amount,
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.CustomValue]: '-',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.LastUpdate]: '-',
			[ECP_EZE_PRODUCT_TABLE_COLUMNS.Action]: {
				id: paylaod.id,
				isAttr: false
			}
		},
		...paylaod.attributes.map((attr) => {
			return {
				[ECP_EZE_PRODUCT_TABLE_COLUMNS.Description]: attr.name,
				[ECP_EZE_PRODUCT_TABLE_COLUMNS.Value]: attr.value || '-',
				[ECP_EZE_PRODUCT_TABLE_COLUMNS.CustomValue]: attr.custom_value || '-',
				[ECP_EZE_PRODUCT_TABLE_COLUMNS.LastUpdate]: attr.updated_at
					? useDateFormat(attr.updated_at, 'DD.MM.YYYY HH:mm').value
					: '-',
				[ECP_EZE_PRODUCT_TABLE_COLUMNS.Action]: {
					id: attr.attribute_id,
					isAttr: true
				}
			}
		})
	]
}

export const mapOfferListFromApi = (
	payload: components['schemas']['GetOffer'][]
): ICPOfferDocumentsTableItem[] => {
	return payload.map((item) => ({
		[ECP_OFFER_DOCUMENTS_TABLE_KEYS.DocumentName]: item.name,
		[ECP_OFFER_DOCUMENTS_TABLE_KEYS.Service]: item.services.map((service) => service.name).join(', '),
		[ECP_OFFER_DOCUMENTS_TABLE_KEYS.DateTime]: useDateFormat(item.date, 'DD.MM.YYYY HH:mm').value,
		[ECP_OFFER_DOCUMENTS_TABLE_KEYS.PerformedBy]: item.performed_by?.name || '-',
		[ECP_OFFER_DOCUMENTS_TABLE_KEYS.Actions]: item.id
	}))
}

export const mapReporListFromApi = (
	payload: components['schemas']['ListProjectReports'][]
): ICPReportsTableRow[] => {
	return payload.map((item) => {
		return {
			[ECP_REPORTS_TABLE_COLUMNS.Name]: item.name,
			[ECP_REPORTS_TABLE_COLUMNS.Service]: item.phase.name || '-',
			[ECP_REPORTS_TABLE_COLUMNS.Status]: item.status || '-',
			[ECP_REPORTS_TABLE_COLUMNS.Result]: item.is_positive
				? 'Positive'
				: 'Negative',
			[ECP_REPORTS_TABLE_COLUMNS.PerformedBy]: item.performed_by.name,
			[ECP_REPORTS_TABLE_COLUMNS.Date]: item.updated_at
				? useDateFormat(item.updated_at, 'DD.MM.YYYY HH:mm').value
				: '-',
			[ECP_REPORTS_TABLE_COLUMNS.Actions]: item.id
		}
	})
}

export const mapProjectControllersFromApi = (
	payload: components['schemas']['GetController']
): ICPProjectControllersTableRow[] => {
	const updatedAt = payload.updated_at
		? useDateFormat(payload.updated_at, 'DD.MM.YYYY HH:mm').value
		: '-'
	return [
		{
			[ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.Description]: 'Hersteller',
			[ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.Information]: payload.manufacturer.name,
			[ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.LastUpdate]: updatedAt
		},
		{
			[ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.Description]: 'Typ',
			[ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.Information]: payload.type.name,
			[ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.LastUpdate]: updatedAt
		},
		{
			[ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.Description]: 'Zertifikatsnummer',
			[ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.Information]: payload.certificate_number || '-',
			[ECP_PROJECT_CONTROLLERS_TABLE_COLUMNS.LastUpdate]: updatedAt
		}
	]
}
