import type { ITabItem } from '~/quality-management/layout/TabNavigation/types'
import { EmployeeRoutesName } from '~/router/modules/quality-management/qualityManagementTypes.ts'

export const generalInfoTabItem: ITabItem = {
	title: 'Allgemeine Informationen',
	value: EmployeeRoutesName.GeneralInfo
}

export const filesTabItem: ITabItem = {
	title: 'Dateien',
	value: EmployeeRoutesName.Files
}
