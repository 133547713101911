import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { ReportFormsRoutesEnum } from '.'

export const getReportFormsRoutes = (app?: App): RouteRecordRaw => {
	return {
		path: '/project/:projectId/E8',
		name: ReportFormsRoutesEnum.E8,
		component: () => import('~/portal/pages/report-forms/e-form.vue'),
		beforeEnter: createAuthGuard(app as any)
	}
}

export const getE9FormRoute = (app?: App): RouteRecordRaw => {
	return {
		path: '/project/:projectId/E9',
		name: ReportFormsRoutesEnum.E9,
		component: () => import('~/portal/pages/report-forms/e-form.vue'),
		beforeEnter: createAuthGuard(app as any)
	}
}

export const getAngebotFormRoute = (app?: App): RouteRecordRaw => {
	return {
		path: '/project/:projectId/angebot',
		name: ReportFormsRoutesEnum.OfferChecklist,
		component: () => import('~/portal/pages/report-forms/e-form.vue'),
		beforeEnter: createAuthGuard(app as any)
	}
}

export const getEzeReportFormsRoute = (app?: App): RouteRecordRaw => ({
	path: '/project/:projectId/eze/:type',
	name: ReportFormsRoutesEnum.Eze,
	component: () => import('~/portal/pages/report-forms/eze.vue'),
	beforeEnter: createAuthGuard(app as any)
})

export const getSystemControllerRoute = (app?: App): RouteRecordRaw => ({
	path: '/project/:projectId/system-controller',
	name: ReportFormsRoutesEnum.SystemController,
	component: () => import('~/portal/pages/report-forms/system-controller.vue'),
	beforeEnter: createAuthGuard(app as any)
})

export const getIntermediateProtectionRoute = (app?: App): RouteRecordRaw => ({
	path: '/project/:projectId/intermediate-protection',
	name: ReportFormsRoutesEnum.IntermediateProtection,
	component: () => import('~/portal/pages/report-forms/intermediate-protection.vue'),
	beforeEnter: createAuthGuard(app as any)
})

export const getTransformerRoute = (app?: App): RouteRecordRaw => ({
	path: '/project/:projectId/transformator',
	name: ReportFormsRoutesEnum.Transformator,
	component: () => import('~/portal/pages/report-forms/transformator.vue'),
	beforeEnter: createAuthGuard(app as any)
})
