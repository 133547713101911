// export * from './some-file-name.ts
export enum STATE_STORE_KEYS {
  UserList = 'UserList',
  CreatUser = 'CreateUser',
  UpdateUser = 'UpdateUser',
  AssineUsers = 'AssineUsers',
  CurrentUser = 'CurrentUser',
  ValidateUser = 'ValidateUser',
  CreationProject = 'CreationProject',
  EMailVerification = 'EmailVerification',
  UserChangePassword = 'UserChangePassword',

  Projects = 'Project',
  BlockProject = 'BlockProject',
  UpdateProject = 'UpdateProject',
  DeleteProject = 'DeleteProject',
  ArchiveProject = 'ArchiveProject',

  DeleteTemplate = 'DeleteTemplate',
  CreationTemplate = 'CreationTemplate',
  GetTemplate = 'GetTemplate',

  AddTimeframe = 'AddTimeframe',
  ActivateTimeframe = 'ActivateTimeframe',

  UpdateSubPhase = 'UpdateSubPhase',
  DocumentChecking = 'DocumentChecking',
  ReviewChecklists = 'ReviewChecklists',
  BillingAddress = 'BillingAddress',

  ProductUpdate = 'ProductUpdate',
  ProductImport = 'ProductImport',

  ChatList = 'ChatList',
  ChatMessageAdd = 'ChatMessageAdd',
  ChatMessageRead = 'ChatMessageRead',
  ChatMessageCount = 'ChatMessageCount',
  ChatNoteList = 'ChatNoteList',
  ChatNoteAdd = 'ChatNoteAdd',
  ChatNoteDelete = 'ChatNoteDelete',
  ChatNoteSend = 'ChatNoteSend',
  ChatNoteUpdate = 'ChatNoteUpdate',
  ChatMessageEmailNotify = 'ChatMessageEmailNotify',
  ChatAllMessageCount = 'ChatAllMessageCount',

  InvoiceInteractive = 'InvoiceInteractive',
  GetClientsList = 'GetClientsList',

  Client = 'Client',
  ClientProjects = 'ClientProjects',
  MemberInteractive = 'MemberInteractive',
  MemberProjects = 'MemberProjects',
  DeleteUser = 'DeleteUser',

  CurrentChecklist = 'CurrentChecklist',
  AddChecklistItem = 'AddChecklistItem',
  DeleteChecklistItem = 'DeleteChecklistItem',
  UpdateChecklistItem = 'UpdateChecklistItem',
  GetChecklistItem = 'GetChecklistItem',
  PostAnswers = 'PostAnswers',
  SetQuestionStatus = 'SetQuestionStatus',
  AddQuestionComment = 'AddQuestionComment',

  ProjectAuthor = 'ProjectAuthor',
  ProjectManagers = 'ProjectManagers',

  getQuestions = 'getQuestions',

  TeamApiInteractive = 'TeamApiInteractive',
  TeamProjects = 'TeamProjects',

  ProjectIdsList = 'ProjectIdsList',
  ProjectAll = 'ProjectAll',
  ProjectCount = 'ProjectCount',

  ActivityLogList = 'ActivityLogList',
  ActivityLogUserList = 'ActivityLogUserList',
  ActivityLogAllList = 'ActivityLogAllList',
  ActivityLogUserAllList = 'ActivityLogUserAllList',

  EmployeesInteractive = 'EmployeesInteractive',

  ChecklistInteractive = 'ChecklistInteractive',

  ProductList = 'ProductList',
  ProductCreate = 'ProductCreate',
  ProductDBUpdate = 'ProductDBUpdate',
  ProductAttributeList = 'ProductAttributeList',
  ProductTypeList = 'ProductTypeList',
  AdditionalEquipment = 'AdditionalEquipment',
  ManufacturerList = 'ManufacturerList',
  ProductFind = 'ProductFind',
  ProductDelete = 'ProductDelete',

  DBProductList = 'DBProductList',

  CertificateList = 'CertificateList',
  CertificateListByProject = 'CertificateListByProject',
  CertificateDeleteById = 'DeleteCertificateById',
  CertificateGenerateById = 'CertificateGenerateById',
  CertificateDownload = 'CertificateDownload',
  CertificateNotifyAll = 'CertificateNotifyAll',

  Offers = 'Offers',
  GenerateOffer = 'GenerateOffer',
  DeleteOffer = 'DeleteOffer',
  DownloadOffer = 'DownloadOffer',
  UploadOffer = 'UploadOffer',
  GenerateOfferConfirmation = 'GenerateOfferConfirmation',

  EmployeeById = 'EmployeeById',
  EmployeeProjects = 'EmployeeProjects',
  EmployeeLis = 'EmployeeLis',
  EmployeeProjectsReassign = 'EmployeeProjectsReassign',
  EmployeeUserUpdate = 'EmployeeUserUpdate',

  Services = 'Services',

  ProjectsIds = 'ProjectsIds',

  EForm = 'EForm',

  ControllerList = 'ControllerList',
  ControllerTypeList = 'ControllerTypeList',
  ControllerFind = 'ControllerFind',
  ControllerDelete = 'ControllerDelete',

  ReportsInteractive = 'ReportsInteractive',
  ReportsDownload = 'ReportsDownload',
  ReportsDownloadTextmarks = 'ReportsDownloadTextmarks',

  ProtectorList = 'ProtectorList',
  ProtectorFind = 'ProtectorFind',
  ProtectorDelete = 'ProtectorDelete',
  ProtectorTypeList = 'ProtectorTypeList',
  ProtectorCompanyList = 'ProtectorCompanyList',

  NotificationList = 'NotificationList',
  NotificationRead = 'NotificationRead',

  CalculationResults = 'CalculationResults',
  Calculate = 'Calculate',
}
