import { defineStore } from 'pinia'

import type {
	IFetchApiProps
} from '~/portal/api'
import {
	fetchApiGetClientsList
} from '~/portal/api'

import type {
	IClientsStoreTypes
} from './'

export const useClientsStore = defineStore('clientsStore', {
	state: (): IClientsStoreTypes => ({
		list: [],
		searchList: [],
		currentPage: 1,
		resultCount: 0,
		isPagination: true
	}),
	actions: {
		resetClientsList () {
			this.list = []
		},
		resetClientsPages () {
			this.currentPage = 1
			this.isPagination = true
		},
		resetSearchList () {
			this.searchList = []
		},
		resetClientsGetListState () {
			this.resetClientsList()
			this.resetClientsPages()
			this.resetSearchList()
		},
		async fetchClientsList (payload: {
			token: IFetchApiProps['token']
		}) {
			if (!payload.token) {
				return
			}

			const response = await fetchApiGetClientsList({
				currentPage: this.currentPage,
				token: payload.token
			})

			if (response) {
				// @ts-expect-error ___
				this.currentPage = response.next ?? (response.previous + 1) ?? 1
				this.resultCount = response.count as number
				// @ts-expect-error ___
				this.list.push(...response.results)
				this.isPagination = !!response.next
			}
		},
		async fetchSearchClients (payload: {
			token: IFetchApiProps['token']
			search: string
		}) {
			const response = await fetchApiGetClientsList({
				currentPage: this.currentPage,
				token: payload.token,
				search: payload.search
			})

			if (response) {
				// @ts-expect-error ___
				this.searchList = response
			}
		}
	}
})
