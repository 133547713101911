import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { EmployeeRoutesNamesEnum } from '.'

export const getEmployeeRouter = (app?: App): RouteRecordRaw => {
	return 		{
		path: '/employee/:id',
		name: EmployeeRoutesNamesEnum.employee,
		component: () => import('~/portal/pages/employee/index.vue'),
		beforeEnter: createAuthGuard(app as any),
		children: [
			{
				path: '',
				name: EmployeeRoutesNamesEnum.employeeInformation,
				component: () => import('~/portal/pages/employee/employee-information.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'employee-projects',
				name: EmployeeRoutesNamesEnum.employeeProjects,
				component: () => import('~/portal/pages/employee/employee-projects.vue'),
				beforeEnter: createAuthGuard(app as any)
			},
			{
				path: 'employee-settings',
				name: EmployeeRoutesNamesEnum.employeeSettings,
				component: () => import('~/portal/pages/employee/employee-settings.vue'),
				beforeEnter: createAuthGuard(app as any)
			}
		]
	}
}
