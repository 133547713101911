import type { App } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

import { createAuthGuard } from '@auth0/auth0-vue'

import { CompanyManagementRoutes } from './'

export const getCompanyManagementRoutes = (app?: App): RouteRecordRaw => ({
	name: CompanyManagementRoutes.CompanyManagement,
	path: '/company-management',
	component: () => import('~/portal/pages/company-management/index.vue'),
	beforeEnter: createAuthGuard(app as any),
	children: [
		{
			path: '',
			name: CompanyManagementRoutes.ActivityLogs,
			component: () => import(
				'~/portal/pages/company-management/tabs/activity-logs.vue'
			),
			beforeEnter: createAuthGuard(app as any)
		},
		{
			path: 'certificates',
			name: CompanyManagementRoutes.Certificates,
			component: () => import(
				'~/portal/pages/company-management/tabs/certificates.vue'
			),
			beforeEnter: createAuthGuard(app as any)
		},
		{
			path: 'certificates',
			name: CompanyManagementRoutes.Certificates,
			component: () => import(
				'~/portal/pages/company-management/tabs/certificates.vue'
			),
			beforeEnter: createAuthGuard(app as any)
		},
		{
			path: 'invoices',
			name: CompanyManagementRoutes.Invoices,
			component: () => import(
				'~/portal/pages/company-management/tabs/invoices.vue'
			),
			beforeEnter: createAuthGuard(app as any)
		},
		{
			path: 'employees',
			name: CompanyManagementRoutes.Employees,
			component: () => import(
				'~/portal/pages/company-management/tabs/employees.vue'
			),
			beforeEnter: createAuthGuard(app as any)
		},
		{
			path: 'checklists',
			name: CompanyManagementRoutes.CheckLists,
			component: () => import(
				'~/portal/pages/company-management/tabs/check-lists.vue'
			),
			beforeEnter: createAuthGuard(app as any)
		},
		{
			path: 'db-products',
			name: CompanyManagementRoutes.DBProducts,
			component: () => import(
				'~/portal/pages/company-management/tabs/db-products.vue'
			),
			beforeEnter: createAuthGuard(app as any)
		},
		{
			path: 'db-controllers',
			name: CompanyManagementRoutes.DBControllers,
			component: () => import(
				'~/portal/pages/company-management/tabs/db-controllers.vue'
			),
			beforeEnter: createAuthGuard(app as any)
		},
		{
			path: 'db-protection',
			name: CompanyManagementRoutes.DBProtection,
			component: () => import(
				'~/portal/pages/company-management/tabs/db-protection.vue'
			),
			beforeEnter: createAuthGuard(app as any)
		}
	]
})

export const getChecklistRoute = (app?: App): RouteRecordRaw => ({
	path: '/company-management/checklists/:checklistId',
	name: CompanyManagementRoutes.Checklist,
	component: () => import('~/portal/pages/checklist.vue'),
	beforeEnter: createAuthGuard(app as any)
})

export const getChecklistItemRoute = (app?: App): RouteRecordRaw => ({
	path: '/company-management/checklists/:checklistId/:itemType/:itemId',
	name: CompanyManagementRoutes.ChecklistItem,
	component: () => import('~/portal/pages/checklist-item.vue'),
	beforeEnter: createAuthGuard(app as any)
})
