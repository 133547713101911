import { defineStore } from 'pinia'

import type {
	IFetchApiDeleteProjectPayload,
	IFetchApiGetProjectPayload,
	IFetchApiProps
	, IFetchGetDraftListPayload
} from '~/portal/api'
import {
	fetchApiDeleteProject,
	fetchApiGetDraftsList,
	fetchApiGetProject
} from '~/portal/api'
import { useProjectCreationStore } from '~/portal/stores'

import type {
	IDraftsStoreTypes
} from './'

export const useDraftsStore = defineStore('draftsStore', {
	state: (): IDraftsStoreTypes => ({
		list: [],
		table: [],
		currentPage: 1,
		resultCount: 0,
		isPagination: true,
		currentDraftId: undefined
	}),
	getters: {
		getProductCreationState () {
			const projectCreationStore = useProjectCreationStore()

			return {
				fetchProjectApi: projectCreationStore.fetchProjectApi,
				createProjectsPayload: projectCreationStore.createProjectsPayload
			}
		}
	},
	actions: {
		resetDraftId () {
			this.currentDraftId = undefined
		},
		resetDraftsList () {
			this.list = []
		},
		resetDraftsCurrentPage () {
			this.currentPage = 1
			this.isPagination = true
		},
		resetDraftsGetListState () {
			this.resetDraftId()
			this.resetDraftsList()
			this.resetDraftsCurrentPage()
		},
		async fetchSaveAsDraft (token: IFetchApiProps['token']) {
			const payload = await this.getProductCreationState
				.createProjectsPayload(
					{
						isDraft: true,
						existsApplicantId: undefined
					}
				)
			await this.getProductCreationState.fetchProjectApi(
				{ payload, token }
			)
		},
		async fetchDeleteDraft (payload: IFetchApiDeleteProjectPayload) {
			await fetchApiDeleteProject({
				token: payload.token,
				id: payload.id
			})
			this.resetDraftsList()
			this.resetDraftsCurrentPage()
			await this.fetchDraftsList({ token: payload.token })
		},
		async fetchDraftsList (payload: IFetchGetDraftListPayload) {
			if (!payload.token) {
				return
			}

			const response = await fetchApiGetDraftsList({
				currentPage: this.currentPage,
				token: payload.token,
				params: {
					ordering: '-updated_at'
				}
			})

			if (response) {
				// eslint-disable-next-line max-len
				// @ts-expect-error ___
				this.currentPage = response?.next ?? response?.previous ?? 1
				this.resultCount = response.count as number
				this.isPagination = !!response?.next && !(this.list.length === this.currentPage)

				if (response?.results) {
					if (this.list.length) {
						this.list.push(...response.results)
					} else {
						this.list = response.results
					}
				}
			}
		},
		async fetchUseDraft (payload: IFetchApiGetProjectPayload) {
			const response = await fetchApiGetProject({
				token: payload.token,
				id: payload.id
			})

			if (response) {
				this.currentDraftId = response.id
				return response
			}
		}
	}
})
