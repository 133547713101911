import { ref } from 'vue'
import type { Ref } from 'vue'

import type { ICPBreadcrumbsPropTypes } from '~/portal/components'

const breadcrumbs = ref<ICPBreadcrumbsPropTypes['data']>([])

export const useBreadcrumbs = (): {
  breadcrumbs: Ref<ICPBreadcrumbsPropTypes['data']>
  setBreadcrumbs: (value: ICPBreadcrumbsPropTypes['data']) => void
} => {
	const setBreadcrumbs = (value: ICPBreadcrumbsPropTypes['data']): void => {
		breadcrumbs.value = value
	}

	return {
		breadcrumbs,
		setBreadcrumbs
	}
}
