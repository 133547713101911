export const toCamelCase = (obj: any): any => {
	if (Array.isArray(obj)) {
		return obj.map(toCamelCase)
	} else if (obj !== null && obj.constructor === Object) {
		return Object.keys(obj).reduce((acc, key) => {
			const camelKey = key.replace(/_([a-z])/g, (_, letter) =>
				letter.toUpperCase()
			)
			acc[camelKey] = toCamelCase(obj[key])
			return acc
		}, {} as Record<string, any>)
	}
	return obj
}

export const toSnakeCase = (obj: any): any => {
	if (Array.isArray(obj)) {
		return obj.map(toSnakeCase)
	} else if (obj !== null && obj.constructor === Object) {
		return Object.keys(obj).reduce((acc, key) => {
			const snakeKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
			acc[snakeKey] = toSnakeCase(obj[key])
			return acc
		}, {} as Record<string, any>)
	}
	return obj
}
