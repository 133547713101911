import { defineStore } from 'pinia'

import type {
	IFetchApiPostAdditionalEquipment,
	IFetchApiUpdateTransformator
} from '~/portal/api'
import {
	fetchApiAdditionalEquipment,
	fetchApiDeleteAdditionalProduct,
	fetchApiPostAdditionalEquipment,
	fetchApiUpdateProduct
} from '~/portal/api'
import type { IAdditionalEquipmentStoreProps } from '~/portal/stores'

export const useAdditionalEquipmentsStore = defineStore('additionalEquipmentStore', {
	state: (): IAdditionalEquipmentStoreProps => ({
		products: [],
		pageCount: 0,
		currentPage: 1,
		isPagination: false
	}),
	actions: {
		resetProducts () {
			this.products = []
			this.pageCount = 0
			this.currentPage = 1
			this.isPagination = false
		},
		async getProductList (payload: {
      token: string
      projectId: number
    }) {
			const response = await fetchApiAdditionalEquipment({
				token: payload.token,
				projectId: payload.projectId,
				params: {
					page: this.currentPage
				}
			})

			if (response) {
				this.pageCount = response.count
				this.currentPage = response.next ??
          (response.previous + 1) ??
          1
				this.products?.push(...response.results)
				this.isPagination = !!response.next
			}
		},
		async addProduct (payload: IFetchApiPostAdditionalEquipment) {
			if (!payload.token) return
			const response = await fetchApiPostAdditionalEquipment(payload)

			if (response) {
				this.resetProducts()
				this.getProductList({
					token: payload.token,
					projectId: payload.projectId
				})
			}
		},
		async deleteProduct (payload: {
      token: string
      projectId: number
      productId: number
    }) {
			const response = await fetchApiDeleteAdditionalProduct(payload)

			if (response) {
				this.products = this.products.filter(
					(item) => item.id !== payload.productId
				)
			}
		},
		async editProduct (payload: IFetchApiUpdateTransformator) {
			if (!payload.token) return

			const response = await fetchApiUpdateProduct(payload)

			if (response) {
				this.resetProducts()
				this.getProductList({
					token: payload.token,
					projectId: payload.projectId
				})
			}
		}
	}
})
