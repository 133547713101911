import type { IFetchApiProps } from '~/portal/api'
import { FETCH_API_METHODS, fetchApi } from '~/portal/api'
import { STATE_STORE_KEYS } from '~/portal/types'

export interface IFetchApiChatPayload {
	projectId: string | number
	token: IFetchApiProps['token']
	forceFetch?: boolean
}

export interface IFetchApiChatFile {
	name: string
	base64: string | undefined
}

export interface IFetchApiChatMessageAdd extends IFetchApiChatPayload {
	text: string
	emailNotify: boolean
	files?: IFetchApiChatFile[]
}

export interface IFetchApiChatMessageMarkAsRead extends IFetchApiChatPayload {
	messageId: string | number
}

export interface IFetchApiChatMessageEmailNotify extends IFetchApiChatPayload {
	messageId: string | number
}

export interface IFetchApiChatNoteDelete extends IFetchApiChatPayload {
	noteId: string | number
}

export interface IFetchApiChatNoteSend extends IFetchApiChatPayload {
	noteId: string | number
}

export interface IFetchApiChatNoteAdd extends IFetchApiChatPayload {
	text: string
	files?: IFetchApiChatFile[]
}

export interface IFetchApiChatNoteUpdate extends IFetchApiChatPayload {
	noteId: string | number
	text: string
}

export type IFetchApiChatAllMessageCount = Omit<IFetchApiChatPayload, 'projectId'>

export const fetchApiChatList = async (
	payload: IFetchApiChatPayload
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/chat/message/list?return_all=true`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ChatList,
		forceFetch: true
	})
}

export const fetchApiChatMessageAdd = async (
	payload: IFetchApiChatMessageAdd
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/chat/message/add`,
		token: payload.token,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ChatMessageAdd,
		payload: {
			text: payload.text,
			email_notify: payload.emailNotify,
			files: payload.files
		}
	})
}

export const fetchApiChatMessageMarkAsRead = async (
	payload: IFetchApiChatMessageMarkAsRead
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/chat/message/${payload.messageId}/read`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ChatMessageRead
	})
}

export const fetchApiChatMessageCount = async (
	payload: IFetchApiChatPayload
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/chat/message/count`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ChatMessageCount,
		forceFetch: payload.forceFetch
	})
}

export const fetchApiChatAllMessageCount = async (
	payload: IFetchApiChatAllMessageCount
) => {
	return await fetchApi({
		url: '/project/list/message/count',
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ChatAllMessageCount,
		forceFetch: payload.forceFetch
	})
}

export const fetchApiChatNoteList = async (
	payload: IFetchApiChatPayload
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/chat/note/list?return_all=true`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ChatNoteList
	})
}

export const fetchApiChatNoteAdd = async (
	payload: IFetchApiChatNoteAdd
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/chat/note/add`,
		token: payload.token,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ChatNoteAdd,
		payload: {
			text: payload.text,
			files: payload.files
		}
	})
}

export const fetchApiChatNoteDelete = async (
	payload: IFetchApiChatNoteDelete
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/chat/note/${payload.noteId}/delete`,
		token: payload.token,
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.ChatNoteDelete
	})
}

export const fetchApiChatNoteSend = async (
	payload: IFetchApiChatNoteSend
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/chat/note/${payload.noteId}/send`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ChatNoteSend
	})
}

export const fetchApiChatNoteUpdate = async (
	payload: IFetchApiChatNoteUpdate
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/chat/note/${payload.noteId}/update`,
		token: payload.token,
		method: FETCH_API_METHODS.Patch,
		pendingKey: STATE_STORE_KEYS.ChatNoteUpdate,
		payload: {
			text: payload.text
		}
	})
}

export const fetchApiChatMessageEmailNotify = async (
	payload: IFetchApiChatMessageEmailNotify
) => {
	return await fetchApi({
		url: `/project/${payload.projectId}/chat/message/${payload.messageId}/email-notify`,
		token: payload.token,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ChatMessageEmailNotify
	})
}
