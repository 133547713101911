import type { RouteLocationNormalized } from 'vue-router'

import { AppLayoutToFileMap, LAppLayoutsEnum } from '~/portal/layouts'

export async function loadLayoutMiddleware (
	route: RouteLocationNormalized
): Promise<void> {
	const { layout } = route.meta

	const normalizedLayoutName = layout || LAppLayoutsEnum.default
	const fileName = AppLayoutToFileMap[normalizedLayoutName]
	const fileNameWithoutExtension = fileName.split('.vue')[0]

	const component = await import(
		`../layouts/${fileNameWithoutExtension}/${fileNameWithoutExtension}.vue`
	)
	route.meta.layoutComponent = component.default
}
