import { defineStore } from 'pinia';
import { ILoaderStore } from './loaderStoreTypes';

export const useLoaderStore = defineStore('qManagementStore', {
  state: (): ILoaderStore => ({
    isLoading: false,
  }),
  actions: {
    toggleIsLoading() {
      this.isLoading = !this.isLoading;
    },
  },
});
