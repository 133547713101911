import type { components } from 'schema.ts'

import type { ICPFormApplicantFormData } from '~/portal/components/form'

export const mapApplicantToApi = (
	data: ICPFormApplicantFormData
): Partial<components['schemas']['UserNested']> => {
	return {
		first_name: data?.firstName || undefined,
		last_name: data?.lastName || undefined,
		phone_number: data?.phoneNumber || undefined,
		email: data?.email || undefined,
		street: data?.street || undefined,
		city: data?.city || undefined,
		index: data?.index || undefined,
		gender: data?.gender || null,
		company_name: data?.companyName
	}
}

export const mapApplicantFromApi = (
	data: components['schemas']['UserNested']
): ICPFormApplicantFormData => {
	return {
		firstName: data?.first_name ?? '',
		lastName: data?.last_name ?? '',
		gender: data?.gender || null,
		email: data?.email || '',
		city: data?.city || '',
		street: data?.street || '',
		phoneNumber: data?.phone_number || '',
		index: data?.index || '',
		companyName: data?.company_name || ''
	}
}
