import type { components } from 'schema.ts'

import type { IFetchApiProps } from '~/portal/api'
import {
	FETCH_API_METHODS,
	fetchApi
} from '~/portal/api'
import { STATE_STORE_KEYS } from '~/portal/types'

export interface IFetchReports {
  token: IFetchApiProps['token']
  projectId: number
  params?: {
    page?: number
    return_all?: boolean
  }
}

export interface IFetchDeleteReport {
  token: IFetchApiProps['token']
  projectId: number
  reportId: number
}

export interface IFetchGenerateReport {
  token: IFetchApiProps['token']
  projectId: number
  payload: components['schemas']['GenerateReport']
}

export interface IFetchUploadReport {
  token: IFetchApiProps['token']
  projectId: number
  payload: {
    name: string
    base64: string
  }
}

export interface IFetchDownloadReport {
  token: IFetchApiProps['token']
  projectId: number
  reportId: number
  params?: {
    type?: 'docx' | 'pdf'
  }
}

export interface IFetchReportNotify {
  token: IFetchApiProps['token']
  projectId: number
  reportId: number
}

export interface IFetchReportSetStatus {
  token: IFetchApiProps['token']
  projectId: number
  reportId: number
  payload: components['schemas']['SetReportStatus']
}

export interface IFetchApiDownloadTextmarks {
  token: IFetchApiProps['token']
  projectId: number
}

export const fetchApiGetReports = async ({
	token,
	projectId,
	params
}: IFetchReports) => {
	const response = await fetchApi({
		token,
		url: `/project/${projectId}/report/list`,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ReportsInteractive,
		params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiDeleteReport = ({
	token,
	projectId,
	reportId
}: IFetchDeleteReport) => {
	return fetchApi({
		token,
		url: `/project/${projectId}/report/${reportId}/delete`,
		method: FETCH_API_METHODS.Delete,
		pendingKey: STATE_STORE_KEYS.ReportsInteractive
	})
}

export const fetchApiGenerateReport = ({
	token,
	projectId,
	payload
}: IFetchGenerateReport) => {
	return fetchApi({
		token,
		url: `/project/${projectId}/report/generate`,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ReportsInteractive,
		payload,
		timeout: 60000 * 5
	})
}

export const fetchApiUploadReport = ({
	token,
	projectId,
	payload
}: IFetchUploadReport) => {
	return fetchApi({
		token,
		url: `/project/${projectId}/report/upload`,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ReportsInteractive,
		payload
	})
}

export const fetchApiDownloadReport = async ({
	token,
	projectId,
	reportId,
	params
}: IFetchDownloadReport) => {
	const response = await fetchApi({
		token,
		url: `/project/${projectId}/report/${reportId}/download`,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ReportsDownload,
		params
	})

	if (response?.data) {
		return response.data
	}
}

export const fetchApiNotifyReport = ({
	token,
	projectId,
	reportId
}: IFetchReportNotify) => {
	return fetchApi({
		token,
		url: `/project/${projectId}/report/${reportId}/notify-all`,
		method: FETCH_API_METHODS.Get,
		pendingKey: STATE_STORE_KEYS.ReportsInteractive
	})
}

export const fetchApiSetReportStatus = ({
	token,
	projectId,
	reportId,
	payload
}: IFetchReportSetStatus) => {
	return fetchApi({
		token,
		url: `/project/${projectId}/report/${reportId}/set-status`,
		method: FETCH_API_METHODS.Put,
		pendingKey: STATE_STORE_KEYS.ReportsInteractive,
		payload
	})
}

export const fetchApiDownloadTextmarks = async ({
	token,
	projectId
}: IFetchApiDownloadTextmarks) => {
	const response = await fetchApi({
		token,
		url: `/project/${projectId}/text-mark/list/export`,
		method: FETCH_API_METHODS.Post,
		pendingKey: STATE_STORE_KEYS.ReportsDownloadTextmarks
	})

	return response?.data
}
