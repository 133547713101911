import { defineStore } from 'pinia'

import type { IFetchApiProps } from '~/portal/api'
import {
	fetchApiDeleteTemplate,
	fetchApiGetTemplate,
	fetchApiGetTemplatesList,
	fetchApiPostCreateProject,
	fetchApiPutUpdateProject
} from '~/portal/api'
import { STATE_STORE_KEYS } from '~/portal/types'

import type {
	ITemplatesListItem,
	ITemplatesStoreTypes
} from './'
export const useTemplatesStore = defineStore('templatesStore', {
	state: (): ITemplatesStoreTypes => ({
		list: [],
		name: '',
		currentPage: 1,
		resultCount: 0,
		isPagination: true,
		currentProjectTemplateId: undefined,
		currentEditTemplateId: undefined
	}),
	actions: {
		resetTemplatesPageCount () {
			this.resultCount = 0
		},
		resetTemplatesCurrentPage () {
			this.currentPage = 1
		},
		resetPagination () {
			this.isPagination = true
		},
		resetTemplatesList () {
			this.list = []
		},
		resetTemplateNameAndId () {
			this.name = ''
			this.currentEditTemplateId = undefined
			this.currentProjectTemplateId = undefined
		},
		resetTemplatesGetListState () {
			this.resetTemplatesCurrentPage()
			this.resetPagination()
			this.resetTemplatesList()
			this.resetTemplateNameAndId()
		},
		async fetchSaveAsTemplate (
			{
				payload,
				token
			}: {
				token: IFetchApiProps['token']
				payload: any
			}
		) {
			if (!token) return

			if (this.currentEditTemplateId) {
				await fetchApiPutUpdateProject({
					payload,
					token,
					pendingKey: STATE_STORE_KEYS.CreationTemplate,
					id: this.currentProjectTemplateId!
				})
				return
			}
			if (!this.currentEditTemplateId) {
				await fetchApiPostCreateProject({
					payload,
					token,
					pendingKey: STATE_STORE_KEYS.CreationTemplate
				})
			}
		},
		async fetchTemplatesList (token: IFetchApiProps['token']) {
			if (!token) {
				return
			}

			const response = await fetchApiGetTemplatesList({
				currentPage: this.currentPage,
				token,
				params: {
					ordering: '-updated_at'
				}
			})

			if (response) {
				// @ts-expect-error ___
				this.currentPage = response?.next ?? response?.previous ?? 1
				this.resultCount = response.count as number
				// eslint-disable-next-line max-len
				this.isPagination = !!response?.next && !(this.list.length === this.currentPage)

				if (response?.results) {
					if (this.list.length) {
						this.list.push(...response.results)
					} else {
						this.list = response.results
					}
				}
			}
		},
		async fetchUseTemplate ({
			id,
			isEdit = false,
			token = ''
		}:{
				id: {
					templateId: number
					projectId: number
				}
				token: IFetchApiProps['token']
				isEdit?: boolean
			}) {
			if (!token) return
			if (isEdit) {
				this.currentEditTemplateId = id.templateId
				this.currentProjectTemplateId = id.projectId
			} else {
				this.currentProjectTemplateId = undefined
				this.currentEditTemplateId = undefined
			}
			const response = await fetchApiGetTemplate({
				token,
				id: id.templateId
			})
			if (response) {
				this.name = response.name
				return response
			}
		},
		async fetchDeleteTemplate (
			templateId: ITemplatesListItem['templateId'],
			token: string
		) {
			const projectTemplateId = this.list!.find((project) => {
				return project.id === templateId
			})!.id

			await fetchApiDeleteTemplate({
				id: projectTemplateId,
				token
			})
			this.resetTemplateNameAndId()
			this.resetTemplatesList()
			this.resetPagination()
			this.resetTemplatesCurrentPage()
			await this.fetchTemplatesList(token)
		}
	}
})
