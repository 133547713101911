import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { storeToRefs } from 'pinia'

import { useStateStore } from '~/portal/stores'
import type { STATE_STORE_KEYS } from '~/portal/types'

const httpClient = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	timeout: 30000,
	headers: {
		'Content-Type': 'application/json'
	}
})

httpClient.interceptors.request.use(function (config) {
	return config
}, function (error: Error) {
	return Promise.reject(error)
})

httpClient.interceptors.response.use(response => {
	if (response.status === 204 || response.status === 201) {
		return response
	}
	return response
}, error => {
	return Promise.reject(error)
})

enum FETCH_API_METHODS {
  Get = 'GET',
	Post = 'POST',
	Put = 'PUT',
	Delete = 'DELETE',
	Patch = 'PATCH'
}

export interface IFetchApiProps {
	url: string
	method: FETCH_API_METHODS
	pendingKey: STATE_STORE_KEYS
	payload?: any
	token?: string
	params?: any
	forceFetch?: boolean
  timeout?: number
}

const fetchApi = async ({
	url,
	method,
	pendingKey,
	payload,
	token,
	params,
	forceFetch,
	timeout
}: IFetchApiProps) => {
	const stateStore = useStateStore()

	const { loading } = storeToRefs(stateStore)

	if (loading.value[pendingKey] && !forceFetch) {
		return
	}

	try {
		if (!forceFetch) {
			stateStore.updateLoadingStatus(true, pendingKey)
		}

		let headers = {}

		if (token) {
			headers = {
				Authorization: `Bearer ${token}`
			}
		}

		const options: AxiosRequestConfig = {
			headers,
			params
		}

		if (timeout) {
			options.timeout = timeout
		}

		switch (method) {
		case FETCH_API_METHODS.Get:
			return await httpClient.get(url,
				options
			)
		case FETCH_API_METHODS.Post:
			return await httpClient.post(url, payload, options)
		case FETCH_API_METHODS.Put:
			return await httpClient.put(url, payload, options)
		case FETCH_API_METHODS.Delete:
			return await httpClient.delete(url, { ...options, data: payload })
		case FETCH_API_METHODS.Patch:
			return await httpClient.patch(url, payload, options)
		default:
			throw new Error(`Unsupported HTTP method: ${method}`)
		}
	} catch (error) {
		stateStore.updateErrorStatus(error, pendingKey)

		throw new Error(JSON.stringify(error))
	} finally {
		if (!forceFetch) {
			stateStore.updateLoadingStatus(false, pendingKey)
		}
	}
}

export {
	FETCH_API_METHODS,
	fetchApi,
	httpClient
}
