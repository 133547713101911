import { isEmpty, isNil, some } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { defineStore } from 'pinia'
import type { components } from 'schema.ts'

import type {
	IFetchApiDeleteProjectPayload,
	IFetchApiProps
} from '~/portal/api'
import {
	FETCH_API_METHODS,
	fetchApi,
	fetchApiDeleteProject
} from '~/portal/api'
import type {
	ICPEZETablePropTypes,
	TEZETableKeys
} from '~/portal/components/base'
import type {
	ICPFormApplicantFormData,
	ICPFormCertificationInfoPropTypes
} from '~/portal/components/form'
import {
	VOLTAGE_LVL
} from '~/portal/components/form'
import {
	mapApplicantToApi,
	mapEZETableToApi,
	mapFormCertificationProductTypeToApi,
	mapPowerPlantAddressToApi
} from '~/portal/mappers'
import { useUserStore } from '~/portal/stores'
import { STATE_STORE_KEYS } from '~/portal/types'
import { fileToBase64 } from '~/portal/utils'

import type {
	IProjectCreationDefinitionPayload,
	IProjectCreationStoreTypes
} from './'

const applicantFormInitialState: ICPFormApplicantFormData = {
	gender: null,
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	street: '',
	city: '',
	index: '',
	companyName: ''
}

const powerPlantAddressInitialState:
	IProjectCreationStoreTypes['powerPlantAddress'] = {
		name: '',
		street: '',
		hallway: '',
		city: '',
		index: ''
	}

const scopeCertificationFormDataInitialState:
	ICPFormCertificationInfoPropTypes['formData'] = {
		personal: '1',
		consumption: '',
		contactPerson: '',
		companyName: '',
		phoneNumber: '',
		email: '',
		street: '',
		city: '',
		voltage: VOLTAGE_LVL?.Medium,
		file: undefined,
		productType: [],
		comment: '',
		gender: null
	}

const ezeTablesState: Record<
	TEZETableKeys,
	ICPEZETablePropTypes['modelValue']
> = {
	New: [],
	Old: [],
	Moderate: []
}
export const useProjectCreationStore = defineStore('projectCreationStore', {
	state: (): IProjectCreationStoreTypes => ({
		projectId: undefined,
		applicantInformation: {
			selectedApplicant: null,
			controlState: 'newApplicant',
			applicant: { ...applicantFormInitialState },
			certificateHolder: { ...applicantFormInitialState }
		},
		powerPlantAddress: { ...powerPlantAddressInitialState },
		scopeCertification: {
			formData: { ...scopeCertificationFormDataInitialState },
			ezeTables: cloneDeep(ezeTablesState)
		}
	}),
	actions: {
		resetPowerPlantAddressState () {
			this.powerPlantAddress = { ...powerPlantAddressInitialState }
		},
		clearScopeCertificationTablesDataEZE () {
			this.scopeCertification.ezeTables = cloneDeep(ezeTablesState)
		},
		clearScopeCertificationFormData () {
			this.scopeCertification.formData = {
				...scopeCertificationFormDataInitialState
			}
		},
		clearApplicantInformationApplicantForm () {
			this.applicantInformation.applicant = {
				...applicantFormInitialState
			}
		},
		clearApplicantInformationCertificateHolderForm () {
			this.applicantInformation.certificateHolder = {
				...applicantFormInitialState
			}
		},
		resetProjectCreationState () {
			this.projectId = undefined
			this.applicantInformation.selectedApplicant = null
			this.applicantInformation.controlState = 'newApplicant'

			this.clearApplicantInformationApplicantForm()
			this.clearApplicantInformationCertificateHolderForm()

			this.resetPowerPlantAddressState()
			this.clearScopeCertificationFormData()
			this.clearScopeCertificationTablesDataEZE()
		},
		fillApplicant (state: ICPFormApplicantFormData) {
			this.applicantInformation.applicant = {
				gender: state.gender ?? null,
				firstName: state.firstName ?? '',
				lastName: state.lastName ?? '',
				email: state.email ?? '',
				phoneNumber: state.phoneNumber ?? '',
				street: state.street ?? '',
				city: state.city ?? '',
				index: state.index ?? '',
				companyName: state.companyName ?? ''
			}
		},
		async createProjectsPayload (
			{
				isDraft = false,
				templateName = undefined,
				existsApplicantId = undefined
			}: {
				isDraft?: boolean
				templateName?: string | undefined
				existsApplicantId?: number | undefined
			}
		) {
			const applicant = this.applicantInformation.applicant
			const scopeFormData = this.scopeCertification.formData

			let payload: any = {
				is_draft: isDraft,
				certificate_holder: null
			}

			const { user } = useUserStore()

			if (
				applicant.firstName &&
				!existsApplicantId &&
				user?.role?.is_employee
			) {
				payload = {
					...payload,
					applicant: { ...mapApplicantToApi(applicant) }
				}
			}

			if (existsApplicantId) {
				payload = {
					...payload,
					existing_applicant: existsApplicantId
				}
			}
			if (
				this.applicantInformation.certificateHolder.firstName && !templateName
			) {
				payload = {
					...payload,
					certificate_holder: mapApplicantToApi(
						this.applicantInformation.certificateHolder
					)
				}
			}

			const isAnyAddressFieldFilled = some(
				this.powerPlantAddress,
				(value) => {
					return !isNil(value) && !isEmpty(value)
				}
			)

			if (isAnyAddressFieldFilled) {
				payload = {
					...payload,
					power_plant_address: mapPowerPlantAddressToApi(
						this.powerPlantAddress
					)
				}
			}

			if (scopeFormData) {
				const scopePayload: components['schemas']['CreateScope'] = {
					voltage_lvl: scopeFormData.voltage,
					usage: {
						own_use: scopeFormData.personal === '1'
					}
				}

				if (scopeFormData.email) {
					scopePayload.network_operator = {
						company_name: scopeFormData.companyName,
						phone_number: scopeFormData.phoneNumber || '',
						email: scopeFormData.email,
						street: scopeFormData.street,
						city: scopeFormData.city
					}

					if (scopeFormData.gender) {
						scopePayload.network_operator.gender = scopeFormData.gender
					}
					if (scopeFormData.contactPerson) {
						scopePayload.network_operator.contact_person = scopeFormData.contactPerson
					}
				}

				if (scopeFormData.consumption && scopeFormData.personal === '1') {
					// @ts-expect-error ___
					scopePayload.usage.consumption_value = scopeFormData.consumption.replace(',', '.')
				}

				if (scopeFormData.productType.length) {
					scopePayload.certification = {
						...mapFormCertificationProductTypeToApi(
							scopeFormData.productType
						)
					}
				}

				if (
					this.scopeCertification.ezeTables.New.length ||
					this.scopeCertification.ezeTables.Old.length ||
					this.scopeCertification.ezeTables.Moderate.length
				) {
					const ezeTablePayload: components['schemas']['EzeUnit'] = {
						new: mapEZETableToApi(
							this.scopeCertification.ezeTables.New
						),
						old: mapEZETableToApi(
							this.scopeCertification.ezeTables.Old
						),
						moderate: mapEZETableToApi(
							this.scopeCertification.ezeTables.Moderate
						)
					}

					scopePayload.eze = { ...ezeTablePayload }
				}
				// eslint-disable-next-line no-console
				if (scopeFormData.file?.length) {
					const renderDocuments = async (): Promise<
						components['schemas']['Document'][] | []
					> => {
						const list: Promise<string | null>[] = scopeFormData.file.map(async (item: string) => {
							return await fileToBase64(item)
						})

						const values = await Promise.all(list)

						return values.map((item, index) => {
							return {
								file_name: scopeFormData.file[index].name,
								file: item?.replace(/^data:.+;base64,/, '') || ''
							}
						})
					}

					scopePayload.documents = await renderDocuments()
				}

				if (scopeFormData.comment) {
					scopePayload.comment = scopeFormData.comment
				}

				payload = {
					...payload,
					scope: cloneDeep(scopePayload)
				}
			}

			if (templateName) {
				payload = {
					...payload,
					template: {
						name: templateName
					}
				}
			}
			return payload
		},
		async fetchProjectApi ({
			payload = {},
			token,
			pendingKey = STATE_STORE_KEYS.CreationProject
		}: {
			payload: IProjectCreationDefinitionPayload
			token: IFetchApiProps['token']
			pendingKey?: STATE_STORE_KEYS
		}
		) {
			await fetchApi({
				method: FETCH_API_METHODS.Post,
				url: '/project/',
				payload,
				pendingKey,
				token
			})
		},
		async fetchProjectCreation (
			token: IFetchApiProps['token'],
			existsApplicantId: number | undefined = undefined
		) {
			const payload = await this.createProjectsPayload({
				existsApplicantId
			})
			await this.fetchProjectApi({ payload, token })
		},
		async fetchUpdateProjectApi ({
			payload = {},
			token,
			pendingKey = STATE_STORE_KEYS.CreationProject,
			id
		}: {
			payload: IProjectCreationDefinitionPayload
			token: IFetchApiProps['token']
			pendingKey?: STATE_STORE_KEYS
			id: number
		}
		) {
			if (id) {
				await fetchApi({
					method: FETCH_API_METHODS.Patch,
					url: `/project/${id}/update`,
					payload,
					pendingKey,
					token
				})
				return
			}
			// eslint-disable-next-line no-console
			console.error('Can\'t get project id')
		},
		async fetchDeleteProject (payload: IFetchApiDeleteProjectPayload) {
			await fetchApiDeleteProject({
				token: payload.token,
				id: payload.id
			})
		},
		fillState (state: Omit<IProjectCreationStoreTypes, 'projects'>) {
			this.projectId = state.projectId
			this.applicantInformation = cloneDeep(state.applicantInformation)
			this.powerPlantAddress = cloneDeep(state.powerPlantAddress)
			this.scopeCertification = cloneDeep(state.scopeCertification)
		}
	}
})
