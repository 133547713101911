export enum SINGLE_PROJECT_ROUTES_NAMES {
    CHAT = 'chat',
    SingleProject = 'singleProject',
    ProjectSettings = 'projectSettings',
    DocumentsChecklist = 'documentsChecklist',
    BillingInformation = 'billingInformation',
    ActivityLogs = 'activityLogs',
    GeneralInformation = 'generalInformation',
    DocumentChecking = 'documentChecking',
    OfferDocuments = 'OfferDocuments',
    Products = 'Products',
    PdfViewerInvoice = 'PdfViewerInvoice',
    PdfViewerOffer = 'PdfViewerOffer',
    PdfViewerCertificate = 'PdfViewerCertificate',
    Reports = 'Reports',
    Certificates = 'Certificates',
    PdfViewerReport = 'PdfViewerReport',
    Calculations = 'Calculations',
}

export interface IPdfViewerCertificateParams {
    projectId: string | number
    certificateId: string | number
    name: string
    file: string
}
