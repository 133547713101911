import type { ICPMultiselectItemTypes } from '~/portal/components'

export enum ECP_VOLTAGE_LVL {
  Medium = 'MEDIUM_VOLTAGE',
  AreaNetwork = 'AREA_NETWORK',
  High = 'HIGH_OR_EXTRA_HIGH'
}

export enum ECP_CERTIFICATION_TYPE {
  PlantCertificate = 'Anlagenzertifikat',
  ConformityCheck = 'Konformitätsprüfung'
}

export interface ICPDialogGenerateOfferFormData {
  prepaymentAmount: number | null
	validDate?: string
	applicableGuidelines: ICPMultiselectItemTypes[]
	facilityCertificatePrice: number | null
	conformityCheckPrice: number | null
	vat: number | null
	voltageLvl: string | null
}

export interface ICPDialogGenerateOfferProps {
  modelValue: boolean
  initialData?: ICPDialogGenerateOfferFormData
  certificates: string[]
}
