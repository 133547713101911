<script setup lang="ts">
import {
	onBeforeUnmount,
	ref
} from 'vue'

import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { EditorContent, useEditor } from '@tiptap/vue-3'

import type {
	ICPTextEditorControlItems,
	ICPTextEditorPropTypes
} from '~/portal/components/base'
import { CPSvgIcon } from '~/portal/components/base'
import { CPTypography } from '~/portal/components/layout'

const props = withDefaults(
	defineProps<ICPTextEditorPropTypes>(),
	{
		error: false,
		errorMessages: '',
		placeholder: 'Hinterlassen Sie hier Ihren Kommentar'
	}
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: ICPTextEditorPropTypes['modelValue']): void
  (e: 'input', value: ICPTextEditorPropTypes['modelValue']): void
}>()

const editor = useEditor({
	extensions: [
		StarterKit,
		Underline,
		TextAlign.configure({
			types: ['heading', 'paragraph']
		}),
		Placeholder.configure({
			placeholder: props.placeholder
		})
	],
	content: props.modelValue,
	onUpdate: (): void => {
		emit('input', editor.value!.getText() ? editor.value!.getHTML() : '')
		emit('update:modelValue', editor.value!.getText() ? editor.value!.getHTML() : '')
	}
})

const controlItems = ref<ICPTextEditorControlItems>([
	[
		{
			icon: 'bold-symbol',
			style: 'bold',
			size: 10
		},
		{
			icon: 'italic-symbol',
			style: 'italic',
			size: 12
		},
		{
			icon: 'underline-symbol',
			style: 'underline',
			size: 15
		}
	],
	[
		{
			icon: 'align-left-symbol',
			style: 'align-left'
		},
		{
			icon: 'align-center-symbol',
			style: 'align-center'
		},
		{
			icon: 'align-right-symbol',
			style: 'align-right'
		},
		{
			icon: 'align-justify-symbol',
			style: 'align-justify'
		}
	],
	[
		{
			icon: 'list-bullet-symbol',
			style: 'list-bullet'
		},
		{
			icon: 'list-numeric-symbol',
			style: 'list-numeric'
		}
	]
])

const onSetStyle = (style: string): void => {
	switch (style) {
	case 'bold':
		editor.value!.chain().focus().toggleBold().run()
		break

	case 'italic':
		editor.value!.chain().focus().toggleItalic().run()
		break

	case 'underline':
		editor.value!.chain().focus().toggleUnderline().run()
		break

	case 'align-left':
		editor.value!.chain().focus().setTextAlign('left').run()
		break

	case 'align-center':
		editor.value!.chain().focus().setTextAlign('center').run()
		break

	case 'align-right':
		editor.value!.chain().focus().setTextAlign('right').run()
		break

	case 'align-justify':
		editor.value!.chain().focus().setTextAlign('justify').run()
		break

	case 'list-bullet':
		editor.value!.chain().focus().toggleBulletList().run()
		break

	case 'list-numeric':
		editor.value!.chain().focus().toggleOrderedList().run()
		break

	default:
		break
	}
}

onBeforeUnmount(() => {
	if (editor.value) {
		editor.value.destroy()
	}
})

defineExpose({
	editor
})
</script>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<template>
  <div
    :class="[
      'cp-text-editor',
      { 'cp-text-editor--disabled': disabled },
      { 'cp-text-editor--error': error },
      $attrs.class
    ]"
  >
    <div
      class="cp-text-editor__inner"
    >
      <div
        v-if="editor"
        class="cp-text-editor__control"
      >
        <div
          v-for="(item, index) in controlItems"
          :key="index"
          class="cp-text-editor__control-group"
        >
          <CPSvgIcon
            v-for="icon in item"
            :key="icon.style"
            :name="icon.icon"
            :size="icon?.size ?? 14"
            class="cp-text-editor__control-icon"
            @click="onSetStyle(icon.style)"
          />
        </div>
      </div>
      <EditorContent
        :editor="editor"
        class="cp-text-editor__editor"
      />
    </div>
    <CPTypography
      v-for="(message, index) in errorMessages"
      :key="index"
      variant="caption2"
      class="cp-text-editor__error"
    >
      {{ message }}
    </CPTypography>
  </div>
</template>

<style lang="scss">
  .cp-text-editor {
    &__inner {
      @apply
      bg-cp-neutral-50
      rounded
      border-solid
      border-1
      border-cp-neutral-200
      ;
    }

    &__control {
      @apply
        flex
        gap-8
        m-[15px]
        pb-4
				px-[5px]
        border
        border-transparent
        border-solid
        border-b-cp-neutral-200
      ;

      &-group {
        @apply
          flex
          gap-4
        ;
      }

			&-icon {
				@apply
					text-cp-neutral-500
					cursor-pointer
				;
			}
    }

    &__error {
      @apply
        mt-3
        px-4
        color-cp-base-red
      ;
    }

    &__editor {
      @apply
				flex
        m-4
				mt-5
				min-h-32
				max-h-100
      ;

			.tiptap {
				@apply
					w-full
					min-h-full
					overflow-y-auto
					text-cp-neutral-700
				;

				&:focus-visible {
					outline: none
				}

				& > * {
					@apply
						mb-3
					;
				}
			}

			ul, ol {
				@apply
					ml-8
				;
			}

      p.is-editor-empty:first-child{
				@apply
					relative
				;

				&::before {
					content: attr(data-placeholder);
					@apply
						absolute
						top-0
						left-0
						text-cp-neutral-500
						pointer-events-none
					;
				}
			}
    }

		&--disabled {
      .cp-text-editor__inner {
        @apply
          opacity-50
          pointer-events-none
        ;
      }
		}
  }
</style>
