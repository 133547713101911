import { computed, watch } from 'vue'
import { isEmpty } from 'lodash'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

import { useAuth0 } from '@auth0/auth0-vue'

import type { ICPHeaderUserTypes } from '~/portal/components/layout'
import type { AccessScopesEnum } from '~/portal/composables/auth-data/authTypes.ts'
import type { IUserStoreType } from '~/portal/stores'
import { useUserStore } from '~/portal/stores'
import { AccountRoutesNamesEnum } from '~/router'

export const useAuthData = () => {
	const router = useRouter()
	const userStore = useUserStore()
	const {
		getAccessTokenSilently,
		isAuthenticated,
		logout
	} = useAuth0()
	const { getUserRole, user } = storeToRefs(userStore)

	const checkHasScope = (scopes: AccessScopesEnum[]): boolean => {
		if (!getUserRole.value) {
			return false
		}

		return scopes.includes(getUserRole.value as AccessScopesEnum)
	}

	const computedUserData = computed(() => {
		return {
			email: user.value
				? user.value.email
				: '' as ICPHeaderUserTypes['email'],
			firstName: user.value
				? user.value.first_name
				: '' as ICPHeaderUserTypes['firstName'],
			secondName: user.value
				? user.value.last_name
				: '' as ICPHeaderUserTypes['secondName']
		} as ICPHeaderUserTypes
	})

	const getCurrentUser = async (): Promise<void> => {
		if (isAuthenticated.value && isEmpty(user.value)) {
			try {
				const token = await getAccessTokenSilently()
				await userStore.fetchCurrentUser(token)
			} catch (error) {
				logout()
			}
		}
	}

	watch(user, async (value: IUserStoreType['user']) => {
		if (value.is_invited) {
			await router.push({ name: AccountRoutesNamesEnum.personalInfo })
		}
	})

	watch(isAuthenticated, async (value: boolean) => {
		if (value) {
			try {
				const token = await getAccessTokenSilently()
				await userStore.fetchCurrentUser(token)
			} catch (error) {
				logout()
			}
		}
	}, {
		immediate: true
	})

	return {
		computedUserData,
		checkHasScope,
		getCurrentUser
	}
}
