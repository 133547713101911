import type { RouteRecordRaw } from 'vue-router'

import {
	authGuard,
	employeeGuard
} from '~/router/modules/quality-management/guards.ts'
import {
	EmployeeRoutesName,
	QualityManagementRoutes
} from '~/router/modules/quality-management/qualityManagementTypes'

export const getQualityManagementRoutes = (): RouteRecordRaw => {
	return {
		path: '/quality-management',
		name: QualityManagementRoutes.QualityManagement,
		component: () => import('~/quality-management/pages/index.vue'),
		children: [
			{
				path: '',
				name: QualityManagementRoutes.Dashboard,
				component: () =>
					import('~/quality-management/pages/dashboard/dashboard.vue')
			},
			{
				path: 'employee/:employeeId/',
				name: QualityManagementRoutes.QMEmployee,
				component: () =>
					import('~/quality-management/pages/employee/index.vue'),
				children: getEmployeeRoutes(),
				beforeEnter: employeeGuard
			},
			{
				path: 'employee/:employeeId/files/edit/:year',
				name: QualityManagementRoutes.EditFiles,
				component: () => import('~/quality-management/pages/employee/edit-files.vue'),
				beforeEnter: employeeGuard
			},
			{
				path: 'employee/:employeeId/:tabType/edit/:year',
				name: QualityManagementRoutes.EditTable,
				component: () => import('~/quality-management/pages/employee/edit-table-view.vue'),
				beforeEnter: employeeGuard
			},
			{
				path: '/:pathMatch(.*)*',
				name: QualityManagementRoutes.NotFound,
				component: () => import('~/quality-management/pages/not-found.vue')
			}
		],
		beforeEnter: authGuard
	}
}

export const getEmployeeRoutes = () => [
	{
		path: '',
		redirect: { name: QualityManagementRoutes.GeneralInfo }
	},
	{
		path: EmployeeRoutesName.GeneralInfo,
		name: QualityManagementRoutes.GeneralInfo,
		component: () => import('~/quality-management/pages/employee/general-info.vue')
	},
	{
		path: EmployeeRoutesName.Files,
		name: QualityManagementRoutes.Files,
		component: () => import('~/quality-management/pages/employee/files.vue')
	},
	{
		path: ':tabType',
		name: QualityManagementRoutes.TablesView,
		component: () => import('~/quality-management/pages/employee/table-view.vue')
	}
]
