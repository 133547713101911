import { defineStore } from 'pinia'
import type { components } from 'schema.ts'

import type { IFetchApiGetServices } from '~/portal/api'
import {
	fetchApiCancelEmployeeInvitation,
	fetchApiDeleteChecklistItem,
	fetchApiDeleteEmployee,
	fetchApiGetChecklists,
	fetchApiGetEmployeeProjects,
	fetchApiGetEmployees,
	fetchApiGetInvoiceList, fetchApiGetServices,
	fetchApiPostInvoiceStatus,
	fetchApiReInviteEmployee,
	fetchApiUpdateChecklistItem,
	fetchCreateChecklistItem
} from '~/portal/api'
import { ECP_MEMBER_STATUSES } from '~/portal/components'
import type { ICompanyManagementStoreTypes } from '~/portal/stores'

export const useCompanyManagementStore = defineStore('companyManagementStore', {
	state: (): ICompanyManagementStoreTypes => ({
		serviceList: [],
		checklist: {},
		checklists: [],
		invoices: [],
		employees: [],
		pageCount: 0,
		currentPage: 1,
		isPagination: false
	}),
	actions: {
		async fetchChecklistItems (payload: {
      token: string
      checklistId: number
    }) {
			const response = await fetchApiGetChecklists({
				token: payload.token,
				params: {
					return_all: true
				}
			})

			if (response?.results) {
				const checklist = response.results.find(
					(checklist: any) => checklist.id === payload.checklistId
				)

				if (checklist) {
					this.checklist = checklist
				}
			}
		},
		async deleteChecklistItem (payload: {
      token: string
      checklistId: number
      checklistItemId: number
    }) {
			const response = await fetchApiDeleteChecklistItem(payload)

			if (response) {
				this.checklist.items = this.checklist.items?.filter(
					(item) => item.id !== payload.checklistItemId
				)
			}
		},
		async updateChecklistItem (payload: {
      token: string
      checklistId: number
      checklistItemId: number
      data: {
        name: string
        description: string
      }
    }) {
			const response = await fetchApiUpdateChecklistItem(payload)

			if (response) {
				const itemIndex = this.checklist.items?.findIndex(
					(item) => item.id === payload.checklistItemId
				)

				if (itemIndex !== undefined && this.checklist.items) {
					this.checklist.items[itemIndex].name = payload.data.name
					this.checklist.items[itemIndex].description = payload.data.description
				}
			}
		},
		async fetchChecklists (payload: { token: string }) {
			const response = await fetchApiGetChecklists({
				token: payload.token,
				params: {
					page: this.currentPage
				}
			})

			if (response) {
				this.pageCount = response.count
				this.currentPage = response.next ??
        (response.previous + 1) ??
        1
				this.isPagination = !!response.next
				this.checklists.push(...response.results)
			}
		},
		async createChecklistItem ({ token, checklistId, data }: {
      token: string
      checklistId: number
      data: {
        name: string
        description: string
      }
    }) {
			if (!token || !checklistId) return

			const response = await fetchCreateChecklistItem({
				token,
				checklistId,
				data
			})

			if (response && this.checklist.items) {
				this.resetChecklists()
				await this.fetchChecklists({ token })
				await this.fetchChecklistItems({ token, checklistId })

				return response.id
			}
		},
		resetPagination () {
			this.currentPage = 1
			this.pageCount = 0
			this.isPagination = false
		},
		resetInvoices () {
			this.resetPagination()
			this.invoices = []
		},
		resetChecklists () {
			this.resetPagination()
			this.checklists = []
		},
		async fetchInvoices (payload:
      {
        token: string
        params?: {
          search?: string
          pm?: string
          status?: string
          date_from?: string
          date_to?: string
					service_id?: string
          type?: string
        }
      }
		) {
			if (!payload.token) return

			const response = await fetchApiGetInvoiceList({
				token: payload.token,
				params: {
					...payload.params,
					page: this.currentPage
				}
			})

			if (response) {
				this.pageCount = response.count
				this.currentPage = response.next ??
        (response.previous + 1) ??
        1
				this.isPagination = !!response.next
				this.invoices.push(...response.results)
			}
		},
		async fetchServices (
			payload: IFetchApiGetServices
		) {
			if (this.serviceList.length) {
				return
			}

			const response = await fetchApiGetServices(payload)

			if (response) {
				this.serviceList = response
			}
		},
		async updateInvoiceStatus ({ token, id, projectId, status }: {
      token: string
      id: number
      projectId: number
      status: components['schemas']['Status800Enum']
    }) {
			const invoiceIndex = this.invoices.findIndex(
				(invoice) => invoice.id === id
			)
			if (invoiceIndex > -1) {
				await fetchApiPostInvoiceStatus({
					token,
					invoiceId: id,
					projectId,
					status
				})
				this.invoices[invoiceIndex].status = status
			}
		},
		resetEmployees () {
			this.resetPagination()
			this.employees = []
		},
		async fetchEmployees (payload: {
      token: string
      params?: {
        search?: string
        role?: string
        status?: string
      }
    }) {
			const response = await fetchApiGetEmployees({
				token: payload.token,
				params: {
					...payload.params,
					page: this.currentPage
				}
			})
			if (response?.results) {
				this.pageCount = response.count || 0
				if (response.next) {
					this.currentPage = +response.next!
				} else if (response.previous) {
					this.currentPage = +response.previous! + 1
				} else {
					this.currentPage = 1
				}
				this.isPagination = !!response.next

				this.employees.push(...response.results)
			}
		},
		async deleteEmployee (payload: { token: string; id: number }) {
			const response = await fetchApiDeleteEmployee(payload)
			if (!response) return

			this.employees = this.employees.filter(
				(employee) => employee.id !== payload.id
			)
			this.pageCount -= 1
		},
		async cancelEmployeeInvitation (payload: {
      token: string
      id: number
    }) {
			const response = await fetchApiCancelEmployeeInvitation(payload)
			if (!response) return

			this.employees = this.employees.filter(
				(employee) => employee.id !== payload.id
			)
			this.pageCount -= 1
		},
		async reInviteEmployee (payload: {
      token: string
      id: number
    }) {
			const employeeIndex = this.employees.findIndex(
				(employee) => employee.id === payload.id
			)

			if (employeeIndex === -1) return

			const projectList = await fetchApiGetEmployeeProjects({
				...payload, params: { return_all: true }
			}).then((response) => {
				return response?.map((project: any) => project.id) || []
			})

			const response = await fetchApiReInviteEmployee({
				token: payload.token,
				id: payload.id,
				role: this.employees[employeeIndex].role.keyword,
				projects: projectList
			})

			if (response) {
				this.employees[employeeIndex].status = ECP_MEMBER_STATUSES.Invited
			}
		}
	}
})
